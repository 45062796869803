import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICustomer, IOrderHistory } from "@Types";
import { ILivitPayMasterCard } from "@Types/Ilivitpay";
import { IActivity } from "@Types/Iactivity";

import { IPrivateNote } from "@Types/Iprivatenote";
import { initialCardState } from "./cardReducer";

type IInitialStateProps = {
  info?: ICustomer;
  orders?: IOrderHistory[];
  notes?: IPrivateNote[];
  list?: ICustomer[];
  card?: ILivitPayMasterCard;
  activities?: IActivity[];
};

const initialState: IInitialStateProps = {
  info: {},
  orders: [],
  notes: [],
  list: [],
  card: initialCardState,
  activities: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateCustomerFields: (state, action: PayloadAction<ICustomer>) => ({
      ...state,
      info: {
        ...state.info,
        ...action.payload,
      },
    }),
    setListCustomers: (state, action: PayloadAction<ICustomer[]>) => ({
      ...state,
      list: action.payload,
    }),
    setCustomerOrders: (state, action: PayloadAction<IOrderHistory[]>) => ({
      ...state,
      orders: action.payload,
    }),
    setCustomer: (state, action: PayloadAction<any>) => ({
      ...state,
      info: action.payload,
    }),

    setCustomerActivities: (state, action: PayloadAction<IActivity[]>) => {
      state.activities = action.payload;
    },

    setCustomerNotes: (state, action: PayloadAction<IPrivateNote[]>) => {
      state.notes = action.payload;
    },

    setCustomerCard: (state, action: PayloadAction<ILivitPayMasterCard>) => ({
      ...state,
      card: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCustomerFields,
  setCustomer,
  setCustomerOrders,
  setListCustomers,
  setCustomerActivities,
  setCustomerCard,
  setCustomerNotes,
} = customerSlice.actions;

export default customerSlice.reducer;
