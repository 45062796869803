import { pgRewardsApi } from "@Libs";

export const extendedApi = pgRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    getBalance: builder.query<any, void>({
      query: () => `/user/balance`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetBalanceQuery, usePrefetch } = extendedApi;
