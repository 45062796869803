import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUserSetting } from "@Types";

import { setting } from "@Store/data";

const initialState: IUserSetting = setting;

export const settingSlicer = createSlice({
  name: "user",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cardFees = 0;
    },
    decrement: (state) => {
      state.cardFees -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.cardFees += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
} = settingSlicer.actions;

export default settingSlicer.reducer;
