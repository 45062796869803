import { ICurrency } from "./Iorderhistory";

export enum IAdvertisementType {
  BUY = "buy",
  SELL = "sell",
}

export enum IAdvertisementStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum ICrypto {
  USDT = "usdt",
  PGPay = "pgpay",
  WISE = "wise",
  MONCASH = "moncash",
  NATCASH = "natcash",
  CASHAPP = "cashapp",
  ZELLE = "zelle",
  PAYPAL = "paypal",
  VENMO = "venmo",
  BUHOLD = "buh",
  UNIBANK = "unibank",
  CAPITALONE = "capitalone",
  CHASE = "chase",
}

export interface IAdvertisement {
  id?: string;
  type?: IAdvertisementType;
  crypto?: ICrypto;
  currency?: ICurrency;
  price?: number;
  total?: number;
  minLimit?: number;
  maxLimit?: number;
  status?: IAdvertisementStatus;
  selectedPaymentMethods?: string[];
  autoReply?: string;
  terms?: string;
  userID?: string;
  createdAt?: string;
  updatedAt?: string;
}
