import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { loan } from "@Store/data";
import { extendedApi } from "@Libs/user/balance";
import { ILoan } from "@Types/Iloan";

const initialState: ILoan = loan;

export const loanSlicer = createSlice({
  name: "balance",
  initialState,
  reducers: {
    substract: (state, action: PayloadAction<number>) => {
      state.outstandingAmount -= action?.payload;
    },

    add: (state, action: PayloadAction<number>) => {
      state.outstandingAmount += action?.payload;
    },

    setLoanBalance: (state, action: PayloadAction<ILoan>) => action.payload,
  },
  extraReducers: (builder) => {
    builder?.addMatcher(
      extendedApi?.endpoints?.getBalance?.matchFulfilled,
      (state, action) => action?.payload
    );
  },
});

export const { substract, add, setLoanBalance } = loanSlicer.actions;

export default loanSlicer.reducer;
