import { createSlice } from "@reduxjs/toolkit";

import {
  GiftCardId,
  IGiftCardTransaction,
  IGlobalGiftCardSettings,
} from "@Types/Igiftcard";
import { IRewardStatus, IRewardsProvider } from "@Types/Irewards";

export type IRedemptionCodeForm = {
  redemptionCode?: string;
  receiver?: string;
  orderId?: string;
  giftCardId?: string;
};

type IInitialGiftCardState = {
  availableGiftcards: IGlobalGiftCardSettings[];
  purchaseGiftCards: IGiftCardTransaction[];
  giftCardUserOrders: IGiftCardTransaction[];
  redemptionForm: IRedemptionCodeForm;
  rewardForm: {
    code: string;
    link: string;
    giftCardId: string;
    description: string;
    status: string;
    giftCards: IGlobalGiftCardSettings[];
    amount: number;
    provider: string;
  };
};

const initialState: IInitialGiftCardState = {
  availableGiftcards: [],
  purchaseGiftCards: [],
  giftCardUserOrders: [],
  redemptionForm: {
    redemptionCode: "",
    receiver: "",
    orderId: "",
    giftCardId: "",
  },
  rewardForm: {
    code: "",
    link: "",
    giftCardId: GiftCardId.NETFLIX,
    description: "",
    status: IRewardStatus.ACTIVE,
    giftCards: [],
    amount: 0,
    provider: IRewardsProvider.RAISE,
  },
};

export const giftCardSlice = createSlice({
  name: "giftcards",
  initialState,
  reducers: {
    setUserAvailableGiftCards: (state, action) => {
      state.availableGiftcards = action.payload;
    },

    setGiftCardUserOrders: (state, action) => {
      state.giftCardUserOrders = action.payload;
    },

    setUserPurchaseGiftCards: (state, action) => {
      state.purchaseGiftCards = action.payload;
    },

    setRewardForm: (state, action) => {
      state.rewardForm = {
        ...state.rewardForm,
        ...action.payload,
      };
    },

    setRedemptionForm: (state, action) => {
      state.redemptionForm = {
        ...state.redemptionForm,
        ...action.payload,
      };
    },
  },
  // extraReducers: (builder) => {
  //   // Add reducers for additional action types here, and handle loading state as needed
  //   builder.addMatcher(
  //     extendedApi.endpoints.getGiftCards.matchFulfilled,
  //     (state, action) => action?.payload
  //   );
  // },
});

// Action creators are generated for each case reducer function
export const {
  setUserAvailableGiftCards,
  setUserPurchaseGiftCards,
  setGiftCardUserOrders,
  setRewardForm,
  setRedemptionForm,
} = giftCardSlice.actions;

export default giftCardSlice.reducer;
