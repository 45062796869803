import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICourse } from "@Types/Icourse";

type IInitialState = {
  list: ICourse[];
  activeCourse: ICourse;
  isCourseInitialized: boolean;
};

const initialState: IInitialState = {
  list: [],
  activeCourse: {},
  isCourseInitialized: false,
};

export const userSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCourseList: (state, action: PayloadAction<ICourse[]>) => {
      state.list = action.payload;
    },

    addCourseToList: (state, action: PayloadAction<ICourse>) => {
      state.list.push(action.payload);
    },

    setActiveCourse: (state, action: PayloadAction<ICourse>) => {
      state.activeCourse = action.payload;
    },

    setIsCourseInitialized: (state, action: PayloadAction<boolean>) => {
      state.isCourseInitialized = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCourseList,
  addCourseToList,
  setActiveCourse,
  setIsCourseInitialized,
} = userSlice.actions;

export default userSlice.reducer;
