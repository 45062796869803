import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IRole, IShopPermission } from "@Types/Ishop";

type IInitialState = {
  list: IShopPermission[];
  activeShopPermission: IShopPermission;
};

const initialState: IInitialState = {
  list: [],
  activeShopPermission: {
    userID: "",
    role: IRole.STAFF,
    shopId: "",
    owenerUserID: "",
    permissions: {},
  },
};

export const shopPermissionSlice = createSlice({
  name: "shopPermission",
  initialState,
  reducers: {
    setShopPermissionList: (
      state,
      action: PayloadAction<IShopPermission[]>
    ) => {
      state.list = action.payload;
    },

    addToShopPermissionList: (
      state,
      action: PayloadAction<IShopPermission>
    ) => {
      state.list.push(action.payload);
    },

    setActiveShopPermission: (
      state,
      action: PayloadAction<IShopPermission>
    ) => {
      state.activeShopPermission = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShopPermissionList,
  addToShopPermissionList,
  setActiveShopPermission,
} = shopPermissionSlice.actions;

export default shopPermissionSlice.reducer;
