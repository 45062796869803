import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ILivitPayMasterCard } from "@Types/Ilivitpay";

export const initialCardState: ILivitPayMasterCard = {
  weavrtoken: "",
  data: {
    id: 0,
    user_email: "",
    weavr_cardid: "",
    card_phone: "",
    name_on_card: "",
    card_pan: "",
    expiration: "",
    cvv: "",
    card_hash: "",
    card_num: "",
    card_cvv: "",
    weavr_accountid: "",
    private_key: "",
    public_key: "",
    url: "",
    referer: "",
    status: "",
    issuing_id: "",
    created_at: "",
    updated_at: "",
  },
  balance: {
    response: {
      mode: "",
      balances: {
        actualBalance: 0,
        availableBalance: 0,
      },
      billingAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        postCode: "",
        state: "",
      },
      cardBrand: "",
      cardLevelClassification: "",
      cardNumber: {
        value: "",
      },
      cardNumberFirstSix: "",
      cardNumberLastFour: "",
      cardholderMobileNumber: "",
      creationTimestamp: 0,
      currency: "",
      cvv: {
        value: "",
      },
      digitalWallets: {
        pushProvisioningEnabled: false,
        walletsEnabled: false,
      },
      expiryMmyy: "",
      expiryPeriodMonths: 0,
      externalHandle: "",
      friendlyName: "",
      id: "",
      nameOnCard: "",
      profileId: "",
      renewalType: "",
      startMmyy: "",
      state: {
        state: "",
      },
      tag: "",
      type: "",
    },
    code: 0,
  },
  statement: {
    response: {
      count: 0,
      responseCount: 0,

      entry: [],
    },
    code: 0,
  },
  uikey: "",
};

export const cardSlicer = createSlice({
  name: "card",
  initialState: initialCardState,
  reducers: {
    setCard: (state, action: PayloadAction<ILivitPayMasterCard>) =>
      action.payload,
  },
});

export const { setCard } = cardSlicer.actions;

export default cardSlicer.reducer;
