/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { appWithTranslation } from "next-i18next";
import { Analytics } from "@vercel/analytics/react";

import Head from "next/head";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { SWRConfig } from "swr";
import { ToastProvider } from "react-toast-notifications";
import Amplify, { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import { Provider as ReduxProvider } from "react-redux";

import { store } from "src/store";

import "../mastercardbrandnewdesign.css";
import "./style.css";
import "../styles/globals.css";

import "@shopify/polaris/build/esm/styles.css";
import "leaflet/dist/leaflet.css";

const region = process.env.NEXT_PUBLIC_AWS_REGION || "us-east-1";

const awsconfig = {
  aws_project_region: region,
  Auth: {
    identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
    region,
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    graphql_endpoint: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    graphql_headers: async () => ({}),
  },
};

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// const WeglotithNoSSR = dynamic(() => import("@Components/embeded/weglot"), {
//   ssr: false,
// });

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.NEXT_PUBLIC_NODE_ENV || "local",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NEXT_PUBLIC_NODE_ENV || "local",
    person: {
      id: 0,
      email: "",
    },
  },
};

function MyApp({ Component, pageProps }) {
  const [, setLoading] = useState(false);
  const [config, setConfig] = useState(rollbarConfig);
  const router = useRouter();

  const start = () => setLoading(true);
  const stop = () => setLoading(false);

  // Handle Progress Bar
  useEffect(() => {
    handleAuthenticatedUser();
    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", stop);
    router.events.on("routeChangeError", stop);

    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", stop);
      router.events.off("routeChangeError", stop);
    };
  }, [router]);

  const handleAuthenticatedUser = async () => {
    try {
      const { attributes } = await Auth.currentAuthenticatedUser();
      const email = attributes?.email;
      const userID = attributes?.sub;

      const newConfig = {
        ...config,
        payload: {
          ...config?.payload,
          person: {
            ...config?.payload?.person,
            email,
            id: userID,
          },
        },
      };

      setConfig(newConfig);
    } catch (error) {
      //  Error authenticated user");
    }
  };

  const componentToRender = () => (
    <ReduxProvider store={store}>
      <AppProvider i18n={enTranslations}>
        <>
          <Head>
            <title>PGecom</title>
            <meta charSet="utf-8" />
            <link rel="icon" href="/pglogo.jpeg" />
            <link rel="alternate" hrefLang="en" href="https://www.pgecom.com" />
            <link rel="alternate" hrefLang="fr" href="https://fr.pgecom.com" />
            <link rel="alternate" hrefLang="es" href="https://es.pgecom.com" />
            <link rel="alternate" hrefLang="ht" href="https://ht.pgecom.com" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Head>
          <ToastProvider autoDismiss>
            <SWRConfig value={{ revalidateOnFocus: false }}>
              {/* <Progress size="xs" isIndeterminate={loading} /> */}
              {/* <ParallaxProvider> */}
              <Component {...pageProps} />
              {/* <WeglotithNoSSR isActive /> */}
              {/* </ParallaxProvider> */}
            </SWRConfig>
          </ToastProvider>
        </>

        <Analytics />
      </AppProvider>
    </ReduxProvider>
  );

  if (process.env.NEXT_PUBLIC_NODE_ENV === "local") {
    return componentToRender();
  }

  return (
    <Provider config={config}>
      <ErrorBoundary>{componentToRender()}</ErrorBoundary>
    </Provider>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "navigation"])),
      // Will be passed to the page component as props
    },
  };
}

export default appWithTranslation(MyApp);
