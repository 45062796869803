import axios from "axios";

type ICryptoPayload = {
  amount: number;
  walletAddress: string;
  crypto: string;
  balanceType?: string;
};

export const buyCrypto = async (payload: ICryptoPayload) => {
  try {
    const response: any = await axios({
      url: "/api/crypto/buy",
      method: "POST",
      data: payload,
    });

    const orderId = response?.data?.orderId;

    return orderId;
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message ||
      error?.data?.message ||
      error?.message ||
      "Error buying crypto";
    throw new Error(errorMessage);
  }
};
