import axios from "axios";

export const sendTopTup = async (payload, token) => {
  try {
    const { data } = await axios({
      url: "/api/topup/estimate",
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message ||
      error?.data?.message ||
      error?.message ||
      "Error getting estimate";
    throw new Error(errorMessage);
  }
};
