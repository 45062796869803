import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "@Types";

import { userData } from "@Store/data";
import { extendedApi } from "@Libs/user/me";

const initialState: IUser = userData;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserAction: (state, action: PayloadAction<IUser>) => ({
      ...state,
      ...action.payload,
    }),
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },

    setIsPINEnabled: (state, action: PayloadAction<boolean>) => {
      state.isPinEnabled = action.payload;
    },

    setUser: (state, action: PayloadAction<IUser>) => action.payload,
  },
  extraReducers: (builder) => {
    // Add reducers for additiona  l action types here, and handle loading state as needed
    builder.addMatcher(
      extendedApi.endpoints.me.matchFulfilled,
      (state, action) => ({
        ...action.payload,
        isInit: true,
      })
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserAction,
  changeLanguage,
  setUser,
  setIsPINEnabled,
} = userSlice.actions;

export default userSlice.reducer;
