import { GiftCardDelivery } from "./Igiftcard";
import { IMonCashOperator } from "./Imoncashoperator";

export interface IGlobalSettings {
  id?: string;
  message?: string;
  isError?: boolean;

  // Operations
  isBusinessOpen?: boolean;

  // User
  announcement?: string;
  asanaSecret?: string;
  operatorId?: string;
  cardFees?: number;
  transFerFees?: number;
  cashAppFees?: number;
  dopExchangeRateAmount?: number;

  transactionLimitAmount?: number;

  zelleFees?: number;
  topupFees?: number;
  paypalFees?: number;
  virtualCardFees?: number;
  physicalCardFees?: number;
  bankRewardFees?: number;
  paymeFees?: number;
  livitPayBalance?: number;

  operators?: IMonCashOperator[];

  // Agents
  agentTopupFees?: number;
  agentTransFerFees?: number;
  agentVirtualCardFees?: number;
  agentCardFees?: number;
  agentPayPalFees?: number;
  agentBankRewardFees?: number;
  agentPhysicalCardFees?: number;

  // Visa Business Card
  isVisaStandardDepositDeliveryEnabled?: boolean;
  isVisaExpressDepositDeliveryEnabled?: boolean;
  isVisaUrgentDepositDeliveryEnabled?: boolean;
  isVisaRegularDepositDeliveryEnabled?: boolean;
  defaultVisaDelivery?: GiftCardDelivery;
  visaVirtualCardFees?: number;
  visaPhysicalCardFees?: number;

  // MasterCard Card
  isMasterCardStandardDepositDeliveryEnabled?: boolean;
  isMasterCardExpressDepositDeliveryEnabled?: boolean;
  isMasterCardUrgentDepositDeliveryEnabled?: boolean;
  isMasterCardRegularDepositDeliveryEnabled?: boolean;
  defaultMasterCardDelivery?: GiftCardDelivery;
  masterCardVirtualCardFees?: number;

  // MonCash
  isMonCashAutomaticWithdrawEnabled?: boolean;
  isMonCashEnabled?: boolean;
  monCashFees?: number;
  isMonCashBillerEnabled?: boolean;
  monCashDepositPercentageFees?: number;
  moncashAutomaticDepositRates?: number;
  moncashAutomaticDepositFees?: number;
  moncashAutomaticDepositPercentageFee?: number;
  moncashManualDepositFees?: number;
  moncashManualDepositRates?: number;
  monCashWithdrawFees?: number;

  // Buh
  buhDepositFees?: number;
  buhDepositPercentageFees?: number;
  buhDepositRates?: number;

  // Unibank
  unibankDepositFees?: number;
  unibankDepositPercentageFees?: number;
  unibankDepositRates?: number;

  // Boolean
  isPaymeEnabled?: boolean;
  isInviteEnabled?: boolean;
  isBankWithdrawEnabled?: boolean;
  isVideoTrainingEnabled?: boolean;
  isPlaidEnabled?: boolean;
  isAmazonGiftCardEnabled?: boolean;
  isExpressDeliveryEnabled?: boolean;
  isEbayGiftCardEnabled?: boolean;
  isStripeCreditCardEnabled?: boolean;
  isCryptoEnabled?: boolean;
  isDivvyCardEnabled?: boolean;
  isUberAccountConnected?: boolean;
  isAmazonAccountConnected?: boolean;
  isGiftCardDeliveryFeesEnabled?: boolean;
  isMasterCardGiftCardEnabled?: boolean;
  isBinanceGiftCardEnabled?: boolean;
  isBinanceHTGiftCardEnabled?: boolean;
  isVisaGiftCardEnabled?: boolean;
  isNetflixGiftCardEnabled?: boolean;
  isPGPayEnabled?: boolean;
  isComplyCubeIdentityEnabled?: boolean;
  isCallCenterEnabled?: boolean;
  isEcommerceEnabled?: boolean;
  isAgentPortalEnabled?: boolean;
  isFlutterWaveCreditCardEnabled?: boolean;
  isTPMasterCardEnabled?: boolean;
  isBusinessEnabled?: boolean;
  isUnibankEnabled?: boolean;
  isTicketDisabled?: boolean;
  isBUHEnabled?: boolean;
  isAnnouncementEnabled?: boolean;
  isReloadlyGiftCardEnabled?: boolean;
  isTransferPamEnabled?: boolean;
  isCryptoPaymentEnabled?: boolean;
  isPayPalEnabled?: boolean;
  isWithdrawEnabled?: boolean;

  isRegisterBusinessEnabledUSA?: boolean;
  isVirtualCardEnabled?: boolean;
  isPayPalRewardsEnabled?: boolean;

  // NatCash
  isNatCashEnabled?: boolean;
  natCashDepositFees?: number;
  natCashDepositPercentageFees?: number;
  natCashDepositRates?: number;
  natCashWithdrawFees?: number;
  natCashWithdrawRates?: number;

  isNewVisaDesign?: boolean;
  isMasterCardUSDEnabled?: boolean;
  isMasterCardCADEnabled?: boolean;
  isTransferEnabled?: boolean;
  isPaymePremiumEnabled?: boolean;
  isZelleEnabled?: boolean;
  isCashAppEnabled?: boolean;
  isCashAppWithdrawEnabled?: boolean;
  isCVCHidden?: boolean;
  isFlutterWavePaymeEnabled?: boolean;
  isUSABankRewardsEnabled?: boolean;
  isPhysicalCardRewardsEnabled?: boolean;
  isBalanceEnabled?: boolean;
  isPartner?: boolean;
  isTopUpEnabled?: boolean;
  isPayPalPayoutEnabled?: boolean;
  isStripeEnabled?: boolean;
  isStripeIssuingEnabled?: boolean;
  isAPIEnabled?: boolean;

  // Sogebank
  sogebankDepositFees?: number;
  sogebankDepositPercentageFees?: number;
  sogebankDepositRates?: number;
  sogebankWithdrawFees?: number;
  sogebankWithRates?: number;

  // External values
  reloadlyBalance?: number;
  twillioBalance?: number;
  dingBalance?: number;

  operations?: any;

  createdAt?: string;
  updatedAt?: string;
}

export interface IGlobalSettingsUpdate {
  id?: string;
  isStandardDelivery?: boolean;
  isRegularDelivery?: boolean;
  isExpressDelivery?: boolean;
  isUrgentDelivery?: boolean;
  defaultDelivery?: boolean;

  depositPercentageFees?: number;
  depositFixedFees?: number;
  depositExchangeRates?: number;

  withdrawFixedFees?: number;
  withdrawPercentageFees?: number;
  withdrawExchangeRates?: number;

  displayName?: string;

  isActivated?: boolean;
  type?: ISettingType;
  provider?: string;
  platform?: string;
}

export enum ISettingType {
  MONCASH = "moncash",
  MONCASH_BILLER = "moncash_biller",
  NATCASH = "natcash",
  ZELLE = "zelle",
  CASHAPP = "cashapp",
  PAYPAL = "paypal",
  VIRTUAL_CARD = "virtual_card",
  PHYSICAL_CARD = "physical_card",
  BANK_REWARD = "bank_reward",
  PAYME = "payme",
  SOGEBANK = "sogebank",
  BNC = "bnc",
  WISE = "wise",
  PAYONEER = "payoneer",
  MERCURY = "mercury",
  BUH = "buh",
  CRYPTO = "crypto",
  SHOPPING = "shopping",
  BANCO_BHD_LEO = "banco_bhd_leo",
  OPERATIONS = "operations",
  STRIPE = "stripe",
  BINANCE = "binance",
  LOAN = "loan",
}
