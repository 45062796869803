import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAdvertiser } from "@Types";

import { extendedApi } from "@Libs/user/me";

const initialState: IAdvertiser = {};

export const advertiserSlice = createSlice({
  name: "advertiser",
  initialState,
  reducers: {
    updateAdvertiser: (state, action: PayloadAction<IAdvertiser>) => ({
      ...state,
      ...action.payload,
    }),

    setAdvertiser: (state, action: PayloadAction<IAdvertiser>) =>
      action.payload,
  },
  extraReducers: (builder) => {
    // Add reducers for additiona  l action types here, and handle loading state as needed
    builder.addMatcher(
      extendedApi.endpoints.me.matchFulfilled,
      (state, action) => ({
        ...action.payload,
        isInit: true,
      })
    );
  },
});

// Action creators are generated for each case reducer function
export const { updateAdvertiser, setAdvertiser } = advertiserSlice.actions;

export default advertiserSlice.reducer;
