import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICollection } from "@Types/Icollection";

type IInitiaiState = {
  list: ICollection[];
  activeCollection: ICollection;
};

const initialState: IInitiaiState = {
  list: [],
  activeCollection: {},
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setCollectionList: (state, action: PayloadAction<ICollection[]>) => {
      state.list = action.payload;
    },

    addCollectionToList: (state, action: PayloadAction<ICollection>) => {
      state.list.push(action.payload);
    },

    setActiveCollection: (state, action: PayloadAction<ICollection>) => {
      state.activeCollection = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCollectionList,
  addCollectionToList,
  setActiveCollection,
} = collectionSlice.actions;

export default collectionSlice.reducer;
