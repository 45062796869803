import { pgRewardsApi } from "@Libs";

export const extendedApi = pgRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    getGlobalSetting: builder.query<void, void>({
      query: () => `/setting/globalsetting`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetGlobalSettingQuery, usePrefetch } = extendedApi;
