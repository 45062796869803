export enum AsanaCustomField {
  IS_AGENT = "1203254793072468",
  EARNING = "1203264683656944",
  SPENDING = "1203306926268631",
  GOURDES = "1203264796987951",
  AMOUNT = "1203264683656942",
  IsManualDepositOrWithdrawal = "1204915010387580",
  TOTAL_PAMENT = "1203264683656946",
  EMAIL = "1201010035875235",
  TRANSACTION_TYPE = "1203264949605816",
  USER_ID = "1203264886900295",
  MON_CASH_DEPOSIT = "1203266793735797",
  STATUS = "1201425713571057",
  PAYPAL_DEPOSIT = "1203306925906412",
  STRIPE_DEPOSIT = "1203306925862241",
  VIRTUAL_CARD_QUANTITY = "1203456078649899",
  PHYSICAL_CARD_QUANTITY = "1203455959035434",
  PREPAID_CARD_QUANTITY = "1203456084194743",
  ORDER_ID = "1203634051723492",
  IS_EXPRESS_DELIVERY = "1204741205608367",
}

export enum AsanaStaff {
  ESTERLING_ACCIME = "1200940043944827",
  NAHAL_MELE = "1200940108230728",
  JUDAS_ROBILLOS = "1200940411205958",
  JUSTINE_MENESE = "1200940515373572",
  CLERMONT_WILLY = "1201145055979048",
  CLERMONT_WILKENSON = "1202192183216078",
  AVIOLE_JEAN = "1201209339236940",
  AINY_POLIQUIT = "1201152694759845",
  JEAN_MAX_HILAIRE = "1200940411103349",
  AMEDEE_ERNS_BAPTISTE = "1202454850720676",
  DANY_AUGUSTIN = "1203173086196301",
  ZACHARY = "1203591881547133",
  HRD = "1194217155364434",
  JONATHAN_ALIX_PIERRE_DORT = "1203173086196324",
}

export enum AgentCustomField {
  YES = "1203254793072498",
  NO = "1203254793074631",
}

export enum AsanaManualDepositOrWithdraw {
  DEPOSIT = "1204915010387581",
  WITHDRAW = "1204915010387582",
}

export enum expressDeliveryCustomField {
  YES = "1204741205608368",
  NO = "1204741205608369",
}

export enum AsanaAgent {
  HRD_TECH = "1194217155364434",
  MIZIK_PROMO = "1203173086196307",
  FOUR_POCH = "1203173086196311",
  DANY_AUGUSTIN = "1203173086196301",
  UNLOCK_GSM = "1202811050575430",
  JEFFREY_SELLER_CARD = "1203173086196309",
}

export enum AsanaProject {
  PG_REWARDS = "1202531353778336",
  CRYPTO = "1202426855741838",
  PG_DEPOSIT_AND_WITHDRAW = "1203264920243618",
  PG_REWARDS_USER = "1203323197835560",
  PG_STAFF = "1203262683309555",
}

export enum AsanaWorkSpace {
  PGECOM = "1200940046299845",
}

export enum AsanaSection {
  TRANSACTION_TYPE = "1203442240351527",
  WITH_DRAW = "1203442240351530",
  CRYPTO = "1203442240351535",
  ZELLE_DEPOSIT = "1203442240351538",
  CASH_APP_DEPOSIT = "1203442240351539",
  SUBSCRIPTION = "1203442240351542",
  CARD_CONNECTION = "1203442240351541",
  RESET_PIN = "1203442240351545",
  NATCASH_DEPOSIT = "1203647609143491",
  TECHNICAL_ISSUE = "1203442240351540",
  PHONE_VERIFICATION = "1203510710595477",
  REFUND = "1203442240351546",
  TRANSACTIONS = "1203442240351549",
  SUDO_DEPOSIT = "1203443157716208",
  LIVITPAY_DEPOSIT = "1203442240351550",
  FEATURE_ACCESS = "1203442240351551",
  OTHER = "1203710208815946",
  ZELLE_ACCESS = "1203442240351552",
  MONCASH_ACCESS = "1203442240351553",
  CASHAPP_ACCESS = "1203442240351564",
  PG_PAY_WOOCOMMERCE_PLUGIN = "1203573781692768",
  CRYPTO_ACCESS = "1203442240351554",
  REGISTER_BUSINESS_USA = "1203442240351555",
  PAYMENT_DEPOSIT = "1203442240351558",
  PAYME_ACCESS = "1203442240351561",
  GIFT_CARD_PURCHASE = "1204228567891973",
  VISA_FUNDS_RELOAD = "1204284952003844",
  MASTERCARD_CREATION = "1204284952003847",
  VISA_CREATION = "1204284952003847",
  PHYSICAL_CARD = "1204682224675557",
  VISA_WITHDRAW_FUNDS = "1204996103584286",
}
export interface AsanaUser {
  gid: string;
  email: string;
  name: string;
  photo: {
    image_21x21: string;
    image_27x27: string;
    image_36x36: string;
    image_60x60: string;
    image_128x128: string;
  };
  resource_type: string;
  workspaces: {
    gid: string;
    name: string;
    resource_type: string;
  };
}

export interface AsanaUserByEmail {
  data: AsanaUser;
}

export enum StatusCustomFieldValues {
  PROGRESS = "1201425713571059",
  IDEA = "1201425713571058",
  BLOCKED = "1201425713571060",
  WAITING = "1202343799626885",
  VERIFIED = "1203297983501316",
  FAILED = "1203298938550397",
  URGENT = "1203321909680034",
  REVISIT = "1203306558694823",
  REQUIRE_APPROVAL = "1203323193749746",
}

export interface IAsanaEvents {
  user: { gid: string; resource_type: string };
  created_at: string;
  action: string;
  resource: {
    gid: string;
    resource_type: string;
    resource_subtype: string;
  };
  parent: any;
  change: { field: string; action: string };
}

export interface AsanaTask {
  gid?: string;
  assignee?: {
    gid?: string;
    name?: string;
    resource_type?: string;
  };
  assignee_status?: string;
  assignee_section?: {
    gid?: string;
    name?: string;
    resource_type?: string;
  };
  completed?: boolean;
  completed_at?: any;
  created_at?: string;
  custom_fields?: [
    {
      gid?: string;
      enabled?: boolean;
      enum_options?: [
        {
          gid?: string;
          color?: string;
          enabled?: boolean;
          name?: string;
          resource_type?: string;
        }
      ];
      enum_value?: any;
      name?: string;
      description?: string;
      created_by?: {
        gid?: string;
        name?: string;
        resource_type?: string;
      };
      display_value?: any;
      resource_subtype?: string;
      resource_type?: string;
      type?: string;
    }
  ];
  due_at?: any;
  due_on?: any;
  followers?: [{ gid?: string; name?: string; resource_type?: string }];
  hearted?: boolean;
  hearts?: [];
  liked?: boolean;
  likes?: [];
  memberships?: [
    {
      project?: {
        gid?: string;
        name?: string;
        resource_type?: string;
      };
      section?: {
        gid?: string;
        name?: string;
        resource_type?: string;
      };
    }
  ];
  modified_at?: string;
  name?: string;
  notes?: string;
  num_hearts?: number;
  num_likes?: number;
  parent?: any;
  permalink_url?: string;
  projects?: [{ gid?: string; name?: string; resource_type?: string }];
  resource_type?: string;
  start_at?: any;
  start_on?: any;
  tags?: [];
  resource_subtype?: string;
  workspace?: {
    gid?: string;
    name?: string;
    resource_type?: string;
  };
}

export interface AsanaTaskCustomField {
  status: string;
  isagent: string;
  email: string;
  amount: string;
  gourdes: string;
  earning: string;
  phone: string;
  manager_approval: string;
  total_payment: string;
  transactiontype: string;
  userid: string;
  mon_cash_deposit: string;
  orderid: string;
  stripe_deposit: string;
  paypal_deposit: string;
  spending: string;
  estimated_time: string;
  virtual_card_quantity: string;
  physical_card_quantity: string;
  prepaid_card_quantity: string;
  orderId: string;
}

export enum AsanaDepositCustomField {
  WITHDRAW = "1204914424189566",
  REFUND = "1204914116340659",
  SOGEBANK_DEPOSIT = "1204914120622414",
  TOPUP = "1204914416498090",
  BINANCE_DEPOSIT = "1204914120644095",
  PAYPAL_DEPOSIT = "1203306925906412",
  FLUTTERWAVE_DEPOSIT = "1204914415180359",
  OFFLINE_DEPOSIT = "1204914273439711",
  CREDIT_DEPOSIT = "1204920410761629",
  WITHDRAW_CREDIT = "1204920412050149",
  VENMO_DEPOSIT = "1204914428310766",
  MONCASH_DEPOSIT = "1204914120307995",
  ZELLE_DEPOSIT = "1204914413939531",
  NATCASH_DEPOSIT = "1204914419543823",
  CASHAPP_DEPOSIT = "1204914409994667",
  CRYPTO_DEPOSIT = "1204914269953073",
  STRIPE_DEPOSIT = "1203306925862241",
  VISA_WITHDRAW = "1204996305136507",
}
