import axios from "axios";

export const createPaymentIntent = async (payload) => {
  try {
    const url = "/api/checkout/createpaymentintent";

    const { data } = await axios({
      url,
      method: "POST",
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    //  Payment Intent Error: ", error);
  }
};

type IRechargeBalancePayload = {
  amount: number;
  fees: number;
  userID: string;
  paymentProvider: string;
  email: string;
  isPayme?: boolean;
  isAgent?: boolean;
  transactionType: string;
  address: string;
  name: string;
};

export const rechargeBalancePaymentIntent = async (
  payload: IRechargeBalancePayload
) => {
  try {
    const url = "/api/checkout/balanceintent";

    const { data } = await axios({
      url,
      method: "POST",
      data: payload,
    });
    return data;
  } catch (error) {
    //  Payment Intent Error: ", error);
    const message = error?.response?.data?.message;
    throw new Error(message || "Error creating payment intent");
  }
};

export const getPaymentIntentById = async (paymentId) => {
  try {
    if (!paymentId) {
      return null;
    }

    const token = window.localStorage.getItem("userToken");
    const url = `/api/checkout/getpaymentintent?paymentId=${paymentId}`;

    const { data } = await axios({
      url,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    //  Payment Intent Error: ", error);
  }
};
