export enum IPINStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface IPIN {
  id: string;
  digit: string;
  status: IPINStatus;
  tryCount?: number;
  userID: string;
  createdAt?: string;
  updatedAt?: string;
}
