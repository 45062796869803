import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "@Types/Icategory";
import { ISubCategory } from "@Types/Isub-category";

type IInitialState = {
  list: ICategory[];
  subCategory: ISubCategory[];
};

const initialState: IInitialState = {
  list: [],
  subCategory: [],
};

export const userSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<ICategory[]>) => {
      state.list = action.payload;
    },

    setSubCategory: (state, action: PayloadAction<ISubCategory[]>) => {
      state.subCategory = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategory, setSubCategory } = userSlice.actions;

export default userSlice.reducer;
