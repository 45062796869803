import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IGlobalSettings } from "@Types";

import { globalSetting } from "@Store/data";
import { extendedApi } from "@Libs/user/globalsetting";

const initialState: IGlobalSettings = globalSetting;

export const globalSettingSlicer = createSlice({
  name: "globalsetting",
  initialState,
  reducers: {
    setGlobalSetting: (state, action: PayloadAction<IGlobalSettings>) =>
      action.payload,

    setOperations: (state, action: PayloadAction<IGlobalSettings>) => {
      state.operations = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addMatcher(
      extendedApi?.endpoints?.getGlobalSetting?.matchFulfilled,
      (state, action) => action?.payload
    );
  },
});

// Action creators are generated for each case reducer function
export const { setGlobalSetting, setOperations } = globalSettingSlicer.actions;

export default globalSettingSlicer.reducer;
