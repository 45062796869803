export enum IImageType {
  PRODUCT = "product",
  VARIANT = "variant",
  COLLECTION = "collection",
  SHOP = "shop",
  USER = "user",
  BANNER = "banner",
  RECEIPT = "receipt",
  CHAT = "chat",
}

export interface IImage {
  id?: string;
  url?: string;
  altText?: string;
  name?: string;
  key?: string;
  position?: string;
  productId?: string;
  orderId?: string;
  shopId?: string;
  publicId?: string;
  variantId?: string;
  collectionId?: string;
  type?: IImageType;
  size?: number;
  userID?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum ICloudinarySource {
  LOCAL = "local",
  URL = "url",
  CAMERA = "camera",
  DROPBOX = "dropbox",
  IMAGE_SEARCH = "image_search",
  SHUTTER_STOCK = "shutterstock",
  GETTY_IMAGES = "gettyimages",
  ISTOCK = "istock",
  UNSPLASH = "unsplash",
  GOOGLE_DRIVE = "google_drive",
}

export enum ICloudinaryEvent {
  SHOW_COMPLETED = "show-completed",
  ABORT = "abort",
  BATCH_CANCELLED = "batch-cancelled",
  CLOSE = "close",
  DISPLAY_CHANGED = "display-changed",
  PUBLICID = "publicid",
  QUEUES_END = "queues-end",
  QUEUES_START = "queues-start",
  RETRY = "retry",
  SOURCE_CHANGED = "source-changed",
  SUCCESS = "success",
  TAGS = "tags",
  UPLOAD_ADDED = "upload-added",
}

export interface ICloudinaryImage {
  id?: string;
  batchId?: string;
  asset_id?: string;
  public_id?: string;
  version?: number;
  version_id?: string;
  signature?: string;
  width?: number;
  height?: number;
  format?: string;
  resource_type?: string;
  created_at?: string;
  tags?: string[];
  pages?: number;
  bytes?: number;
  type?: string;
  etag?: string;
  placeholder?: false;
  url?: string;
  secure_url?: string;
  folder?: string;
  original_filename?: string;
  api_key?: string;
  path?: string;
  thumbnail_url?: string;
}

export interface ICloudinaryWidgetResult {
  info: ICloudinaryImage;
  event: ICloudinaryEvent;
}

export enum IImageIndex {
  BY_SHOP_ID = "byShopId",
  BY_PRODUCT_ID = "byProductId",
}

export interface IProductImage extends IImage {
  productId: string;
  position?: string;
}

export interface IShopImage extends IImage {
  shopId: string;
}

export interface IReceiptImage extends IImage {
  receiptId: string;
}

export interface ICollectionImage extends IImage {
  collectionId: string;
}

export interface IProfileImage extends IImage {
  userID: string;
}
