import { pgRewardsApi } from "@Libs";
import { IUser } from "@Types";
import { IGiftCard } from "@Types/Igiftcard";
import { IIntegration } from "@Types/Iintegration";
import { IAuthorizationParams, IOrderParams } from "@Types/Iparams";
import {
  IGiftCardPayload,
  IMonCashDepositPayload,
  IMonCashWithdrawPayload,
  IPGPayDeposit,
  ITPCardAddFundsPayload,
  IVirtualCardPayload,
} from "@Types/Ipayload";

export const extendedApi = pgRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    // Queries
    me: builder.query<IUser, void>({
      query: () => `/user/me`,
    }),

    getUserSession: builder.query<IUser, void>({
      query: () => `/token/usersession`,
    }),

    getUser: builder.query<any, string>({
      query: () => `/user`,
    }),

    getGiftCards: builder.query<any, void>({
      query: () => `/user/giftcard`,
    }),

    searchOrders: builder.query<any, any>({
      query: (params: any) => ({
        url: `/order/search`,
        params,
      }),
    }),

    getOrdersByDate: builder.query<any, IOrderParams>({
      query: (payload: IOrderParams) => ({
        url: `/order`,
        params: payload,
      }),
    }),

    getUserByUserID: builder.query<any, any>({
      query: (payload: any) =>
        //  Payload: ", payload);
        `/admin/user?userID=${payload?.userID}&isPrepaid=${payload?.isPrepaid}`,
    }),

    getTPCardHolderId: builder.query<any, void>({
      query: () => `/card/mastercard/retrieve`,
    }),

    getAuthorization: builder.query<any, IAuthorizationParams>({
      query: ({ cardHolderId, cardId, isPrepaid }: IAuthorizationParams) => ({
        url: `/authorization?person=${isPrepaid ? "prepaid" : "user"}`,
        params: {
          cardHolderId,
          cardId,
        },
      }),
    }),

    // Mutation
    buyGiftCard: builder.mutation<IGiftCard, IGiftCardPayload>({
      query: (body: IGiftCardPayload) => ({
        url: `user/giftcard`,
        method: "POST",
        body,
      }),
    }),

    // Mutation
    withdrawFundsToMonCash: builder.mutation<any, IMonCashWithdrawPayload>({
      query: (body: IMonCashWithdrawPayload) => ({
        url: `user/withdraw/moncashwithdraw`,
        method: "POST",
        body,
      }),
    }),

    // Mutation
    createPrepaidCard: builder.mutation<any, IGiftCardPayload>({
      query: (body: IGiftCardPayload) => ({
        url: `card/mastercard/create`,
        method: "POST",
        body,
      }),
    }),

    // Mutation
    createVirtualCard: builder.mutation<any, IVirtualCardPayload>({
      query: (body: IVirtualCardPayload) => ({
        url: `card/visa`,
        method: "POST",
        body,
      }),
    }),

    // Mutation
    createPhysicallCard: builder.mutation<any, IVirtualCardPayload>({
      query: (body: IVirtualCardPayload) => ({
        url: `card/physicalcard`,
        method: "POST",
        body,
      }),
    }),

    // Mutation
    pgPayDeposit: builder.mutation<IPGPayDeposit, any>({
      query: (body: IPGPayDeposit) => ({
        url: `pgpay/token`,
        method: "POST",
        body,
      }),
    }),

    // Mutation
    createIntegration: builder.mutation<IIntegration, string>({
      query: (id?: string) => ({
        url: `developer`,
        method: "POST",
        body: {
          id,
        },
      }),
    }),

    // Mutation
    monCashDeposit: builder.mutation<IMonCashDepositPayload, any>({
      query: (body: IMonCashDepositPayload) => ({
        url: `moncash/token`,
        method: "POST",
        body,
      }),
    }),

    updateProfile: builder.mutation<IUser, any>({
      query: (body: IUser) => ({
        url: "user/profile",
        method: "PUT",
        body,
      }),
    }),

    addFunds: builder.mutation<ITPCardAddFundsPayload, any>({
      query: (body: ITPCardAddFundsPayload) => ({
        url: "card/mastercard/addbalance",
        method: "POST",
        body,
      }),
    }),

    changeUserEmail: builder.mutation<any, string>({
      query: (email: string) => ({
        url: "user/changeemail",
        method: "POST",
        body: {
          email,
        },
      }),
    }),

    startVerification: builder.mutation<any, void>({
      query: (body: any) => ({
        url: "user/identity",
        method: "POST",
        body,
      }),
    }),

    sendMonCashQRCode: builder.mutation<any, any>({
      query: (body: any) => ({
        url: "moncash/qrcode/sendcode",
        method: "POST",
        body,
      }),
    }),

    verifyMonCashQRCode: builder.mutation<any, any>({
      query: (body: any) => ({
        url: "moncash/qrcode/verifycode",
        method: "POST",
        body,
      }),
    }),

    generateMonCashQRCode: builder.mutation<any, any>({
      query: (body: any) => ({
        url: "moncash/qrcode/generatemoncashqrcode",
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useMeQuery,
  useBuyGiftCardMutation,
  useGetGiftCardsQuery,
  useGetUserSessionQuery,
  useGetUserByUserIDQuery,
  usePrefetch,
  useSearchOrdersQuery,
  useCreateIntegrationMutation,
  useGetOrdersByDateQuery,
  useGetUserQuery,
  useGetAuthorizationQuery,
  usePgPayDepositMutation,
  useMonCashDepositMutation,
  useGetTPCardHolderIdQuery,
  useAddFundsMutation,
  useChangeUserEmailMutation,
  useUpdateProfileMutation,
  useStartVerificationMutation,
  useSendMonCashQRCodeMutation,
  useCreateVirtualCardMutation,
  useCreatePhysicallCardMutation,
  useCreatePrepaidCardMutation,
  useVerifyMonCashQRCodeMutation,
  useGenerateMonCashQRCodeMutation,
  useWithdrawFundsToMonCashMutation,
} = extendedApi;
