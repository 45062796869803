import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ITheme } from "@Types/Itheme";

const initialState: ITheme = {};

export const themeReducer = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ITheme>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setTheme } = themeReducer.actions;

export default themeReducer.reducer;
