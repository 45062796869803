import axios from "axios";

type ITransactions = {
  cardId?: string;
  cardHolderId?: string;
  isPrepaid?: boolean;
};

export const getCardAuthorizations = async (payload: ITransactions) => {
  try {
    const params = { ...payload };

    let url = `/api/authorization`;

    if (payload.isPrepaid) {
      url += "?person=prepaid";
    }

    const { data } = await axios({
      url,
      params,
    });
    return data;
  } catch (error) {
    throw new Error("Error card transaction user card information");
  }
};
