import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@Graphql/mutations";

type IPinPayload = {
  pin: string;
  token?: string;
};

export const deleteUserPIN = (pinId) =>
  API.graphql(
    graphqlOperation(mutations.deletePin, {
      input: {
        id: pinId,
      },
    })
  );

export const createPin = async (payload: IPinPayload) => {
  const url = "/api/pin/createpin";

  const response: any = await axios({
    url,
    method: "POST",
    data: payload,
  });

  const data = response?.data;

  return data;
};
