import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import userReducer from "@Store/userreducer";
import giftCardReducer from "@Store/giftcardreducer";
import memberReducer from "@Store/memberreducer";
import balanceReducer from "@Store/balancereducer";
import settingReducer from "@Store/settingreducer";
import globalSettingReducer from "@Store/globalsettingreducer";
import paymeReducer from "@Store/paymereducer";
import orderhistoryReducer from "@Store/orderhistoryreducer";
import virtualcardReducer from "@Store/virtualcardreducer";
import privatenoteReducer from "@Store/privatenotereducer";
import customerreducer from "@Store/customerreducer";
import logreducer from "@Store/logreducer";
import tppCardReducer from "@Store/tppCardReducer";
import userListReducer from "@Store/userListReducer";
import integrationReducer from "@Store/integrationReducer";
import orderDetailReducer from "@Store/orderdetailreducer";
import shopReducer from "@Store/shopReducer";
import cardReducer from "@Store/cardReducer";
import productReducer from "@Store/productreducer";
import collectionReducer from "@Store/collectionreducer";
import businessReducer from "@Store/businessreducer";
import shopPermissionReducer from "@Store/shoppermissionreducer";
import themeReducer from "@Store/themeReducer";
import siteReducer from "@Store/siteReducer";
import bannerReducer from "@Store/bannerReducer";
import menuReducer from "@Store/menuReducer";
import categoryReducer from "@Store/categoryReducer";
import courseReducer from "@Store/courseReducer";
import shopperReducer from "@Store/shopperReducer";
import locationReducer from "@Store/locationReducer";
import affiliateReducer from "@Store/affiliateReducer";
import loanReducer from "@Store/loanReducer";
import investmentReducer from "@Store/investmentReducer";
import advertiserReducer from "@Store/advertiserReducer";

import { pgRewardsApi } from "@Libs";

export const store = configureStore({
  reducer: {
    user: userReducer,
    giftCards: giftCardReducer,
    profile: memberReducer,
    orderDetail: orderDetailReducer,
    balance: balanceReducer,
    logs: logreducer,
    customer: customerreducer,
    notes: privatenoteReducer,
    integrations: integrationReducer,
    setting: settingReducer,
    shops: shopReducer,
    locations: locationReducer,
    courses: courseReducer,
    business: businessReducer,
    users: userListReducer,
    collections: collectionReducer,
    globalSetting: globalSettingReducer,
    payme: paymeReducer,
    card: cardReducer,
    shopPermissions: shopPermissionReducer,
    products: productReducer,
    orders: orderhistoryReducer,
    virtualCards: virtualcardReducer,
    tppData: tppCardReducer,
    theme: themeReducer,
    site: siteReducer,
    banner: bannerReducer,
    affiliate: affiliateReducer,
    menu: menuReducer,
    loan: loanReducer,
    category: categoryReducer,
    shopper: shopperReducer,
    investments: investmentReducer,
    advertiser: advertiserReducer,
    [pgRewardsApi.reducerPath]: pgRewardsApi.reducer,
  },
  devTools: process.env.NEXT_PUBLIC_NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pgRewardsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
