import { pgRewardsApi } from "@Libs";
import { IGlobalSettings, IOrderHistory, IUser } from "@Types";
import { IOrderParams } from "@Types/Iparams";
import {
  IGiftCardPayload,
  INotePayload,
  ITPCardAddFundsPayload,
  IVirtualCardPayload,
} from "@Types/Ipayload";

type IGetUserByUserIDPayload = { userID: string; isAgent?: boolean };

export const adminUserApi = pgRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymeByUserID: builder.query<any, string>({
      query: (userID) => `/admin/payme?userID=${userID}`,
    }),
    getUserByUserID: builder.query<any, IGetUserByUserIDPayload>({
      query: ({ userID, isAgent }: IGetUserByUserIDPayload) =>
        isAgent
          ? `/agent/user?userID=${userID}`
          : `/admin/user?userID=${userID}`,
    }),

    searchUsers: builder.query<any, string>({
      query: (searchValue?: string) =>
        `/admin/user/search?value=${searchValue}`,
    }),

    searchOrders: builder.query<any, any>({
      query: (params: any) => ({
        url: `/admin/orders/search`,
        params,
      }),
    }),

    getProductsByShopId: builder.query<any, any>({
      query: (params: any) => ({
        url: `/admin/products`,
        params,
      }),
    }),

    getOrdersByDate: builder.query<any, IOrderParams>({
      query: (payload: IOrderParams) => ({
        url: `/admin/orders`,
        params: payload,
      }),
    }),

    getNotesByUserID: builder.query<any, string>({
      query: (userID: string) => `/admin/notes?userID=${userID}`,
    }),

    // Mutatation
    createNote: builder.mutation<any, INotePayload>({
      query: (body: INotePayload) => ({
        url: `/admin/notes`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PrivateNotes"],
    }),

    // Mutatation
    purchaseGiftCard: builder.mutation<any, IGiftCardPayload>({
      query: (body: IGiftCardPayload) => ({
        url: `/admin/giftcard/buygiftcard`,
        method: "POST",
        body,
      }),
    }),

    updateGlobalSettings: builder.mutation<any, IGlobalSettings>({
      query: (body: IGlobalSettings) => ({
        url: `/admin/globalsettings`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [],
    }),

    updateAdminOrder: builder.mutation<any, IOrderHistory>({
      query: (body: IGlobalSettings) => ({
        url: `/admin/orders/orderhistory`,
        method: "POST",
        body,
      }),
      invalidatesTags: [],
    }),

    adminAddFunds: builder.mutation<ITPCardAddFundsPayload, any>({
      query: (body: ITPCardAddFundsPayload) => ({
        url: "/admin/mastercard/addfunds",
        method: "POST",
        body,
      }),
    }),

    // Mutation
    createAdminVirtualCard: builder.mutation<any, IVirtualCardPayload>({
      query: (body: IVirtualCardPayload) => ({
        url: `/admin/mastercard/create`,
        method: "POST",
        body,
      }),
    }),

    updateUser: builder.mutation<any, IUser>({
      query: (body: IUser) => ({
        url: `/admin/user`,
        method: "PUT",
        body,
      }),
    }),

    addFundsFromPendingToBalance: builder.mutation<any, any>({
      query: (body: any) => ({
        url: `/admin/pending`,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaymeByUserIDQuery,
  useGetUserByUserIDQuery,
  useSearchUsersQuery,
  useGetNotesByUserIDQuery,
  useGetOrdersByDateQuery,
  useCreateNoteMutation,
  useSearchOrdersQuery,
  useUpdateGlobalSettingsMutation,
  useUpdateUserMutation,
  useUpdateAdminOrderMutation,
  usePurchaseGiftCardMutation,
  useAdminAddFundsMutation,
  useCreateAdminVirtualCardMutation,
  useAddFundsFromPendingToBalanceMutation,
  useGetProductsByShopIdQuery,
} = adminUserApi;
