import { IImage, TransactionType, UserType } from "@Types";
import { GiftCardDelivery, GiftCardId } from "./Igiftcard";
import { IProduct } from "./Iproduct";

export enum Platform {
  PG_REWARDS = "pgrewards",
  MARKET_PLACE = "marketplace",
  E_LEARNING = "elearning",
  EXTERNAL = "external",
  ALIEXPRESS = "aliexpress",
  ASANA = "asana",
  ALGOLIA = "algolia",
  SHEIN = "shein",
  CHROME = "chrome",
  PG_PAY = "pgpay",
}

export enum IPaymentMethod {
  STRIPE = "stripe",
  PAYPAL = "paypal",

  // MonCash
  MONCASH = "moncash",
  MONCASH_MANUEL = "moncash_manuel",
  MONCASH_USD = "moncash_usd",
  MONCASH_HTG = "moncash_htg",

  // NatCash
  NATCASH = "natcash",

  // US Banks
  ZELLE = "zelle",
  NOW_PAYMENT = "nowpayment",
  CASHAPP = "cashapp",
  WISE = "wise",
  BANK_OF_AMERICA = "bank_of_america",
  USABANK = "usabank",
  PAYONEER = "payoneer",

  // Dominican Banks
  BANK_OF_BHD_LEON = "bank_of_bhd_leon",
  BANK_OF_RESERVAS = "bank_of_reservas",

  // Haiti Banks
  BUH_USD = "buh_usd",
  BUH_HTG = "buh_htg",
  SOGEBANK_USD = "sogebank_usd",
  SOGEBANK_HTG = "sogebank_htg",
  UNIBANK_USD = "unibank_usd",
  UNIBANK_HTG = "unibank_htg",

  // Credit Card
  FLUTTERWAVE = "flutterwave",
  DEBIT_CREDIT_CARD = "debit_credit_card",
  PG_PAY = "pgpay",
  BALANCE = "balance",

  // Transfer
  CARIBE_EXPRESS = "caribe_express",
  WESTERN_UNION = "western_union",
  MONEY_GRAM = "money_gram",
  RIA = "ria",
  UNITRANSFER = "unitransfer",

  // Crypto Wallets
  BINANCE = "binance",
  CRYPTO = "crypto",
  COINBASE = "coinbase",
  METAMASK = "metamask",
  RAINBOW = "rainbow",
  WALLETCONNECT = "walletconnect",
  TRUST_WALLET = "trust_wallet",
  PHANTOM = "phantom",
  LEDGER = "ledger",
  RABBY_WALLET = "rabby_wallet",
  UNISWAP_WALLET = "uniswap_wallet",
  BROWSER_WALLET = "browser_wallet",
}

export interface IRedemptionCode {
  cardNumber?: string;
  pinCode?: string;
}

export interface IOrderMetadata {
  giftCardId?: GiftCardId;
  email?: string;
  giftCardName?: string;
  delivery?: GiftCardDelivery;
  amount?: number;
  firstName?: string;
  lastName?: string;
  userID?: string;
  userFee?: number;
  token?: string;
  webhookUrl?: string;
  type?: string;
}

export interface IOrderHistory {
  id?: string;
  description?: string;
  orderId?: string;
  sender?: string;
  status?: string;
  paymentStatus?: string;
  transactionType?: TransactionType | any;
  amount?: number;
  redemptionCode?: IRedemptionCode[];
  giftCardId?: GiftCardId;
  phone?: string;
  quantity?: number;
  userType?: UserType;
  userFee?: number;
  newBalance?: number;
  isManual?: boolean;
  checkoutUrl?: string;
  paymentMethod?: IPaymentMethod;
  customerFullName?: string;
  userID?: string;
  deliveryMethod?: GiftCardDelivery;
  deliveryDueDate?: string;
  gourdes?: number;
  dop?: number;
  currency?: ICurrency;
  contactId?: string;
  redeemptionInstructions?: string;
  errorUrl?: string;
  successUrl?: string;
  webhookUrl?: string;
  customerId?: string;
  invoiceId?: string;
  receiver?: string;
  trackingNumber?: string;
  shippingProvider?: IShippingProvider;
  cardUrl?: string;
  productIds?: string[];
  shopId?: string;
  metadata?: IOrderMetadata & any;
  externalTransactionId?: any;
  isGiftCardCompleted?: boolean;
  platform?: Platform | string;
  operatorId?: string;
  redirectUrl?: string;
  images?: IImage[];
  products?: IProduct[];
  updatedAt?: string;
  createdAt?: string;
}

export type IOrderHistoryUser = {
  id?: string;
  userID?: string;
};

export type IPrepaidCardOrder = {
  sdEmail: string;
  rcEmail: string;
  amount: number;
  balance: number;
  orderId: string;
  userID: string;
};

export type IPartialOrder = {
  description?: string;
  sender?: string;
  transactionType?: TransactionType;
  status: string;
  amount: number;
  userFee?: number;
  receiver?: string;
  newBalance?: number;
  orderId?: string;
  userID?: string;
  platform?: string;
  phone?: string;
  gourdes?: number;
  successUrl?: string;
  errorUrl?: string;
  cardUrl?: string;
  operatorId?: string;
};

export enum ICurrency {
  USD = "usd",
  HTG = "htg",
  DOP = "dop",
  EUR = "eur",
  GBP = "gbp",
  CAD = "cad",
}

export enum IOrderIndex {
  BY_USER = "byUser",
  BY_SHOP_ID = "byShopId",
  BY_STATUS = "byStatus",
  BY_TRANSACTION_TYPE = "byTransactionType",
  BY_SENDER = "bySender",
  BY_RECEIVER = "byReceiver",
  BY_CUSTOMER_ID = "byCustomerId",
  BY_ORDER_ID = "byOrderId",
  BY_USER_AND_TRANSACTION_TYPE = "byUserTransactionType",
}

export enum IShippingProvider {
  DHL = "dhl",
  FEDEX = "fedex",
  UPS = "ups",
  USPS = "usps",
  SPEEDX = "speedx",
}
