import axios from "axios";

export const fetchUserToken = async ({
  token,
  ip,
  fingerprint,
  device,
}: {
  token: string;
  fingerprint: string;
  ip?: string;
  device?: any;
}) => {
  try {
    const payload: any = {
      token,
      fingerprint,
      ip,
      device,
    };

    const { data } = await axios({
      url: "/api/token/issue",
      method: "POST",
      data: payload,
    });

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || "Error fetching token");
  }
};
