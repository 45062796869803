import axios from "axios";

type ITransactions = {
  cardId?: string;
  cardHolderId?: string;
  token: string;
  isPrepaid?: boolean;
};

export const getCardTransactions = async (payload: ITransactions) => {
  try {
    const params = { ...payload };
    delete params.token;

    let url = `/api/transaction`;

    if (payload.isPrepaid) {
      url += "?person=prepaid";
    }

    const { data } = await axios({
      url,
      params,
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
    });
    return data;
  } catch (error) {
    throw new Error("Error card transaction user card information");
  }
};
