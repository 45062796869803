import { createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";
import { IVirtualCard } from "@Types";

const initialState: IVirtualCard[] = [];

export const virtualcardReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.push();
    },
    decrement: (state) => {
      state.push();
    },
    incrementByAmount: (state) => {
      //  Action: ", action);
      state.push();
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
} = virtualcardReducer.actions;

export default virtualcardReducer.reducer;
