import axios from "axios";

type IVirtualCardPayload = {
  amount: number;
  userID: string;
  firstName?: string;
  lastName?: string;
  isPhysical: boolean;
  isPrepaid?: boolean;
  bulk?: string;
  billingAddress: any;
  person: string;
  email: string;
};

export const createVirtualCard = async (payload: IVirtualCardPayload) => {
  try {
    const cardPayload = { ...payload };
    delete cardPayload.userID;

    const response: any = await axios({
      url: "/api/card/mastercard/create",
      method: "POST",
      data: cardPayload,
    });

    const data = response?.data;
    const cardId = data?.id;
    const cardHolderId = data?.cardholder?.id;

    return {
      ...data,
      cardId,
      cardHolderId,
    };
  } catch (error) {
    //  Error creating card: ", error?.response);
    const errorData =
      error?.response?.data?.message ||
      error?.data?.message ||
      error?.message ||
      "Error creating card";
    throw new Error(errorData || "Error creating card");
  }
};

type IPrepaidPayload = {
  token: string;
  email: string;
  person: string;
  isPhysical: boolean;
  billingAddress: any;
  fullName: string;
  amount: number;
};

export const createPrepaidCard = async (payload: IPrepaidPayload) => {
  try {
    const cardPayload = { ...payload };
    delete cardPayload.token;
    const response: any = await axios({
      url: "/api/prepaid/card",
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
      method: "POST",
      data: cardPayload,
    });

    const prepaidUrl = response?.data?.prepaidUrl;

    return prepaidUrl;
  } catch (error) {
    //  Error creating card");
    throw new Error("Error creating card");
  }
};

type ICreateCardHolder = {
  userID: string;
  amount: number;
  name: string;
  email: string;
  token: string;
  country: string;
  phoneNumber?: string;
  line1?: string;
  city?: string;
  state?: string;
  postal_code?: string;
};

export const createVirtualCardHolder = async (
  payload: ICreateCardHolder
): Promise<string> => {
  try {
    const response: any = await axios({
      url: "/api/card/createcardholder",
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
      method: "POST",
      data: payload,
    });

    const cardHolderId = response?.data?.cardHolderId;

    return cardHolderId;
  } catch (error) {
    //  Error: ", error);
    //  Error fetching card transactions");
    return null;
  }
};

type ICardDetailsPayload = {
  cardId: string;
  pin: string;
  isExtend: boolean;
  cardHolderId: string;
  isPrepaid?: boolean;
  isPhysical?: boolean;
};

export const getCardInformation = async (payload: ICardDetailsPayload) => {
  try {
    const data = await getUserCardInformation(payload);
    return data;
  } catch (error) {
    //  Error retrieving the card information");
    throw new Error(error?.message);
  }
};

export const getUserCardInformation = async (payload: ICardDetailsPayload) => {
  try {
    const params = { ...payload };

    let url = `/api/card`;

    if (payload?.isPrepaid) {
      url += "?person=prepaid";
    }

    const { data } = await axios({
      url,
      params,
    });
    return data;
  } catch (error) {
    const errorDataMsg =
      error?.response?.data?.message ||
      error?.data?.message ||
      error?.message ||
      "Error retrieving user card information";
    throw new Error(errorDataMsg);
  }
};
