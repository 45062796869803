import axios from "axios";

import { TransactionType } from "@Types";

type IOrderVirtualCardPayload = {
  rcEmail: string;
  sdEmail: string;
  amount: number;
  name: string;
  transactionType: TransactionType;
};

export const orderVirtualCard = async (payload: IOrderVirtualCardPayload) => {
  try {
    const { data } = await axios({
      url: "/api/balance/virtualcard",
      method: "POST",
      data: payload,
    });
    return data;
  } catch (error) {
    const errorData = error?.response.data;
    return errorData;
  }
};
