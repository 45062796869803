import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { extendedApi } from "@Libs/user/me";
import { IShopper } from "@Types/Ishopper";

const initialState: IShopper = {
  language: "en",
};

export const shopperSlice = createSlice({
  name: "shopper",
  initialState,
  reducers: {
    updateUserAction: (state, action: PayloadAction<IShopper>) => ({
      ...state,
      ...action.payload,
    }),
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },

    setShopper: (state, action: PayloadAction<IShopper>) => action.payload,
  },
  extraReducers: (builder) => {
    // Add reducers for additiona  l action types here, and handle loading state as needed
    builder.addMatcher(
      extendedApi.endpoints.me.matchFulfilled,
      (state, action) => ({
        ...action.payload,
        isInit: true,
      })
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserAction,
  changeLanguage,
  setShopper,
} = shopperSlice.actions;

export default shopperSlice.reducer;
