import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ITPPCardRetrieval, ITPPCardTransaction } from "@Types";

import { extendedApi } from "@Libs/user/balance";

type IInitialStateProps = {
  card: ITPPCardRetrieval;
  orders: ITPPCardTransaction[];
};

const initialState: IInitialStateProps = {
  orders: [],
  card: {},
};

export const balanceSlicer = createSlice({
  name: "tppCard",
  initialState,
  reducers: {
    setTppCard: (state, action: PayloadAction<ITPPCardRetrieval>) => {
      state.card = action.payload;
    },
    setTppCardOrders: (state, action: PayloadAction<ITPPCardTransaction[]>) => {
      state.orders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder?.addMatcher(
      extendedApi?.endpoints?.getBalance?.matchFulfilled,
      (state, action) => action?.payload
    );
  },
});

export const { setTppCard, setTppCardOrders } = balanceSlicer.actions;

export default balanceSlicer.reducer;
