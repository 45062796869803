import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IBalance } from "@Types";

import { balance } from "@Store/data";
import { extendedApi } from "@Libs/user/balance";

const initialState: IBalance = balance;

export const balanceSlicer = createSlice({
  name: "balance",
  initialState,
  reducers: {
    substract: (state, action: PayloadAction<number>) => {
      state.amount -= action?.payload;
    },

    substractPending: (state, action: PayloadAction<number>) => {
      state.pendingAmount -= action?.payload;
    },
    add: (state, action: PayloadAction<number>) => {
      state.amount += action?.payload;
    },

    addCredit: (state, action: PayloadAction<number>) => {
      state.credit += action?.payload;
    },

    subtractCredit: (state, action: PayloadAction<number>) => {
      state.credit -= action?.payload;
    },
    addPending: (state, action: PayloadAction<number>) => {
      state.pendingAmount += action?.payload;
    },

    setBalance: (state, action: PayloadAction<IBalance>) => action.payload,
  },
  extraReducers: (builder) => {
    builder?.addMatcher(
      extendedApi?.endpoints?.getBalance?.matchFulfilled,
      (state, action) => action?.payload
    );
  },
});

export const {
  substract,
  add,
  substractPending,
  addPending,
  setBalance,
  addCredit,
  subtractCredit,
} = balanceSlicer.actions;

export default balanceSlicer.reducer;
