export interface IPayme {
  id?: string;
  username?: string;
  name?: string;
  profileImage?: string;
  amount?: number;
  companyName?: string;
  facebookLink?: string;
  twitterUsername?: string;
  instagramUsername?: string;
  isAffiliateEnabled?: boolean;
  userID?: string;
  successUrl?: string;
  isPaymeEnabled?: boolean;
  calendlyLink?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum PaymentOptions {
  MONCASH_USD = "moncash_usd",
  MONCASH_GOURDES = "moncash_gourdes",
  FLUTTERWAVE = "flutterwave",
  STRIPE = "stripe",
  PAYPAL = "paypal",
  CRYPTO = "crypto",
  BINANCE = "binance",
  CASHAPP = "cashapp",
  ZELLE = "zelle",
  PG_PAY = "pgpay",
  CARD = "card",
}
