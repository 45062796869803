import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IBanner } from "@Types/Ibanner";

type Props = {
  list: IBanner[];
  current: IBanner;
};

const initialState: Props = {
  list: [],
  current: {},
};

export const bannerReducer = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setCurrentBanner: (state, action: PayloadAction<IBanner>) => {
      state.current = action.payload;
    },

    addBannerToList: (state, action: PayloadAction<IBanner>) => {
      state.list.push(action.payload);
    },

    setBannerList: (state, action: PayloadAction<IBanner[]>) => {
      state.list = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentBanner,
  addBannerToList,
  setBannerList,
} = bannerReducer.actions;

export default bannerReducer.reducer;
