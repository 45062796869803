import { GiftCardId } from "./Igiftcard";

export enum IRewardStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted",
  PENDING = "pending",
  REDEEMED = "redeemed",
  EXPIRED = "expired",
  CANCELLED = "cancelled",
  USED = "used",
  INVALID = "invalid",
  FAILED = "failed",
  SUCCESS = "success",
}

export enum IRewardsProvider {
  PGECOM = "pgecom",
  RELOADLY = "reloadly",
  RAISE = "raise",
  AMAZON = "amazon",
}

export interface IRewards {
  id?: string;
  code?: string;
  link?: string;
  name?: string;
  description?: string;

  amount?: number;

  provider?: IRewardsProvider;

  giftCardId?: GiftCardId;
  status?: IRewardStatus;
  platform?: string;
  orderId?: string;

  userID?: string;

  createdAt?: string;
  updatedAt?: string;
}
