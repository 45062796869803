// Events used to send email from Crisp CRM

import { IPhysicalCardStatus } from "./Iuser";

export enum EventName {
  DECLINED = "task:declined",
  DEPOSIT_DECLINED = "deposit:declined",
  WITHDRAW_DECLINED = "withdraw:declined",
  PIN_RESET = "pin:reset",
  BECOME_AGENT = "user:become_agent",
  AGENT_DEMOTION = "agent:become_user",
  PAYPAL_PAYOUT = "payout:paypal",
  TICKET = "ticket",
  ORDER_HISTORY = "order:history",
  WITHDRAW_PROCESSING = "withdraw:processing",
  PREPAID_CARD = "card:prepaid",

  // Transactions
  TRANSACTION_COMPLETE = "transaction:complete",

  // Auth
  LOGIN = "login",

  // User
  USER_ACTIVATED = "user:activated",
  USER_DISABLED = "user:disabled",
  USER_DISABLED_MFA = "user:disabled_mfa",
  USER_CHANGE_EMAIL = "user:change_email",

  // Deposit Events
  ZELLE_DEPOSIT = "zelle:deposit",
  BANK_DEPOSIT = "bank:deposit",
  BINANCE_DEPOSIT = "binance:deposit",
  PAYPAL_DEPOSIT_APPROVED = "paypal:deposit:approved",
  PAYPAL_DEPOSIT = "paypal:deposit",
  CASHAPP_DEPOSIT = "cashapp:deposit",
  MONCASH_DEPOSIT = "moncash:deposit",
  MONCASH_WITHDRAW = "moncash:withdraw",
  WITHDRAW_COMPLETE = "withdraw:complete",
  CRYPTO_WITHDRAW = "crypto:withdraw",
  PAYMENT_DEPOSIT = "payment:deposit",
  VISA_WITHDRAW_APPROVED = "visa:withdraw:approved",
  VISA_WITHDRAW_DECLINED = "visa:withdraw:declined",
  MANUAL_WITHDRAW = "manual:withdraw",
  MANUAL_DEPOSIT = "manual:deposit",
  ADD_FUNDS_TO_CARD = "card:add_funds",

  PG_PAY_PAYMENT = "payment:pgpay_sent",
  TOP_UP_DEPOSIT = "receiver:topup",
  REFUND_RECEIVED = "refund:received",
  MONCASH_TRANSACTION = "moncash:transaction",
  MONCASH_AUTOMATIC_WITHDRAW = "moncash:automatic_withdraw",
  PG_PAY_PAYMENT_RECEIVED = "payment:pgpay_received",

  // Virtual Cards
  CREATE_VIRTUAL_CARD = "card:create_virtual",
  CARD_TRANSACTION = "card:transaction",
  CARD_CONNECTED = "card:connected",
  CREATE_VIRTUAL_CARD_NOTIFICATION_OFF = "card:create_virtual_notification_off",
  VIRTUAL_CARD_UPDATED = "card:update_virtualcard",
  PREPAID_CONNECTED = "prepaidcard:connected",

  // Physical Cards
  PHYSICAL_CARD_UPDATED = "card:update_physicalcard",

  // Document Events
  DOCUMENT_EXPIRED = "document:expired",
  DOCUMENT_VERIFIED = "document:verified",
  DOCUMENT_NOT_SUPPORTED = "document:notsupported",
  DOCUMENT_UNVERIFIED = "document:unverified",

  // Account blocked/unblocked
  ACCOUNT_UNBLOCKED = "account:unblocked",
  ACCOUNT_BLOCKED = "account:blocked",
  ACCOUNT_LOCKED_PIN = "account:locked:pin",

  // Program - depracated
  PROGRAM_AMAZON = "program:amazon",
  PROGRAM_SHEIN = "program:shein",
  PROGRAM_UBER = "program:uber",
  PROGRAM_ALI_EXPRESS = "program:aliexpress",
  PROGRAM_ALIBABA = "program:alibaba",

  // Shop
  SHOP_INVITE = "shop:invite",
  SHOP_CREATED = "shop:created",
  SHOP_INVITATION_ACCEPTED = "shop:invitation_accepted",
  SHOP_INVITATION_REJECTED = "shop:invitation_rejected",

  // Feature Access Granted
  CASH_APP_ACCESS_GRANTED = "feature_request_approved",
  ZELLE_ACCESS_GRANTED = "feature_request_approved",
  MONCASH_ACCESS_GRANTED = "feature_request_approved",
  PAYME_ACCESS_GRANTED = "feature_request_approved",
  PG_PAY_ACCESS_GRANTED = "feature_request_approved",
  CRYPTO_ACCESS_GRANTED = "feature_request_approved",
  MONCASH_WITHDRAW_ACCESS_GRANTED = "feature_request_approved",
  UNIBANK_ACCESS_GRANTED = "feature_request_approved",
  BUH_ACCESS_GRANTED = "feature_request_approved",

  // Feature Access Declined
  CASH_APP_ACCESS_DECLINED = "feature_access_declined",
  ZELLE_ACCESS_DECLINED = "feature_access_declined",
  MONCASH_ACCESS_DECLINED = "feature_access_declined",
  PAYME_ACCESS_DECLINED = "feature_access_declined",
  PG_PAY_ACCESS_DECLINED = "feature_access_declined",
  CRYPTO_ACCESS_DECLINED = "feature_access_declined",
  MONCASH_WITHDRAW_ACCESS_DECLINED = "feature_access_declined",
  UNIBANK_ACCESS_DECLINED = "feature_access_declined",
  BUH_ACCESS_DECLINED = "feature_access_declined",
  FEATURE_DECLINED = "feature_access_declined",

  // Affiliates
  AFFILIATE_EARNING = "affiliate:earning",
  AFFILIATE_GIFT_CARD_EARNING = "affiliate:giftcard_earning",

  // Gift Cards
  GIFTCARD_RECEIVE = "giftcard:receive",
  GIFTCARD_GUEST_PURCHASE = "giftcard:guest_purchase",

  // Funds
  FUNDS_SUBMISSION_REQUEST = "funds:submission_request",
  FUNDS_SUBMISSION_APPROVE = "funds:submission_approve",

  // Courses
  COURSE_PURCHASE = "course:purchase",
}

export type CrispEvent = EventName | IPhysicalCardStatus;
