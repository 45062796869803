import axios from "axios";

export const getPlatformBalance = async (token) => {
  try {
    const { data } = await axios({
      url: "/api/balance/retrieve",
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    const errorData = error?.response?.data || {};
    throw new Error(
      errorData.message || errorData.error || "Error getting platform balance"
    );
  }
};
