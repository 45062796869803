import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPayme } from "@Types";

import { globalSetting } from "@Store/data";
import { extendedApi } from "@Libs/user/payme";

const initialState: IPayme = globalSetting;

export const paymeSlicer = createSlice({
  name: "payme",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.amount = 0;
    },
    decrement: (state) => {
      state.amount -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.amount += action.payload;
    },
    setPayme: (state, action: PayloadAction<IPayme>) => action.payload,
  },
  extraReducers: (builder) => {
    builder?.addMatcher(
      extendedApi.endpoints.getPayme.matchFulfilled,
      (state, action) => action.payload
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  setPayme,
} = paymeSlicer.actions;

export default paymeSlicer.reducer;
