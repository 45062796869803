/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      id
      phoneNumber
      operatorName
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      id
      phoneNumber
      operatorName
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      id
      phoneNumber
      operatorName
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      cardFees
      virtualCardFees
      transFerFees
      paypalFees
      topUpFees
      bankRewardFees
      physicalCardFees
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      cardFees
      virtualCardFees
      transFerFees
      paypalFees
      topUpFees
      bankRewardFees
      physicalCardFees
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      cardFees
      virtualCardFees
      transFerFees
      paypalFees
      topUpFees
      bankRewardFees
      physicalCardFees
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createIntegrations = /* GraphQL */ `
  mutation CreateIntegrations(
    $input: CreateIntegrationsInput!
    $condition: ModelIntegrationsConditionInput
  ) {
    createIntegrations(input: $input, condition: $condition) {
      id
      description
      apiKey
      integrateName
      accessToken
      clientSecret
      clientKey
      clientId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrations = /* GraphQL */ `
  mutation UpdateIntegrations(
    $input: UpdateIntegrationsInput!
    $condition: ModelIntegrationsConditionInput
  ) {
    updateIntegrations(input: $input, condition: $condition) {
      id
      description
      apiKey
      integrateName
      accessToken
      clientSecret
      clientKey
      clientId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrations = /* GraphQL */ `
  mutation DeleteIntegrations(
    $input: DeleteIntegrationsInput!
    $condition: ModelIntegrationsConditionInput
  ) {
    deleteIntegrations(input: $input, condition: $condition) {
      id
      description
      apiKey
      integrateName
      accessToken
      clientSecret
      clientKey
      clientId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createGlobalSettings = /* GraphQL */ `
  mutation CreateGlobalSettings(
    $input: CreateGlobalSettingsInput!
    $condition: ModelGlobalSettingsConditionInput
  ) {
    createGlobalSettings(input: $input, condition: $condition) {
      id
      announcement
      asanaSecret
      operatorId
      monCashFees
      transactionLimitAmount
      monCashWithdrawFees
      zelleFees
      cashAppFees
      cardFees
      transFerFees
      topupFees
      paypalFees
      virtualCardFees
      isStripeCreditCardEnabled
      bankRewardFees
      physicalCardFees
      paymeFees
      agentPhysicalCardFees
      agentCardFees
      agentTransFerFees
      agentTopupFees
      agentPayPalFees
      agentVirtualCardFees
      agentBankRewardFees
      isAPIEnabled
      isVideoTrainingEnabled
      isCryptoPaymentEnabled
      isPaymeEnabled
      isVisaGiftCardEnabled
      isAmazonGiftCardEnabled
      isMasterCardCADEnabled
      isMasterCardUSDEnabled
      isNetflixGiftCardEnabled
      isEbayGiftCardEnabled
      isDivvyCardEnabled
      isMasterCardGiftCardEnabled
      isTPMasterCardEnabled
      isBinanceGiftCardEnabled
      isComplyCubeIdentityEnabled
      isGiftCardDeliveryFeesEnabled
      isCryptoEnabled
      isFlutterWaveCreditCardEnabled
      isFlutterWavePaymeEnabled
      isBinanceHTGiftCardEnabled
      isPaymePremiumEnabled
      isReloadlyGiftCardEnabled
      isUnibankEnabled
      isBUHEnabled
      isTicketDisabled
      isWithdrawEnabled
      isMonCashAutomaticWithdrawEnabled
      isPartner
      isPayPalPayoutEnabled
      isPGPayEnabled
      isInviteEnabled
      isPayPalEnabled
      isTopUpEnabled
      isNewVisaDesign
      isRegisterBusinessEnabledUSA
      isTransferPamEnabled
      isAnnouncementEnabled
      isBankWithdrawEnabled
      isStripeEnabled
      isCashAppEnabled
      isZelleEnabled
      isAgentPortalEnabled
      isCallCenterEnabled
      isVirtualCardEnabled
      isPayPalRewardsEnabled
      isTransferEnabled
      isMonCashEnabled
      isPlaidEnabled
      isNatCashEnabled
      isUSABankRewardsEnabled
      isPhysicalCardRewardsEnabled
      isBalanceEnabled
      isStripeIssuingEnabled
      isCVCHidden
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalSettings = /* GraphQL */ `
  mutation UpdateGlobalSettings(
    $input: UpdateGlobalSettingsInput!
    $condition: ModelGlobalSettingsConditionInput
  ) {
    updateGlobalSettings(input: $input, condition: $condition) {
      id
      announcement
      asanaSecret
      operatorId
      monCashFees
      transactionLimitAmount
      monCashWithdrawFees
      zelleFees
      cashAppFees
      cardFees
      transFerFees
      topupFees
      paypalFees
      virtualCardFees
      isStripeCreditCardEnabled
      bankRewardFees
      physicalCardFees
      paymeFees
      agentPhysicalCardFees
      agentCardFees
      agentTransFerFees
      agentTopupFees
      agentPayPalFees
      agentVirtualCardFees
      agentBankRewardFees
      isAPIEnabled
      isVideoTrainingEnabled
      isCryptoPaymentEnabled
      isPaymeEnabled
      isVisaGiftCardEnabled
      isAmazonGiftCardEnabled
      isMasterCardCADEnabled
      isMasterCardUSDEnabled
      isNetflixGiftCardEnabled
      isEbayGiftCardEnabled
      isDivvyCardEnabled
      isMasterCardGiftCardEnabled
      isTPMasterCardEnabled
      isBinanceGiftCardEnabled
      isComplyCubeIdentityEnabled
      isGiftCardDeliveryFeesEnabled
      isCryptoEnabled
      isFlutterWaveCreditCardEnabled
      isFlutterWavePaymeEnabled
      isBinanceHTGiftCardEnabled
      isPaymePremiumEnabled
      isReloadlyGiftCardEnabled
      isUnibankEnabled
      isBUHEnabled
      isTicketDisabled
      isWithdrawEnabled
      isMonCashAutomaticWithdrawEnabled
      isPartner
      isPayPalPayoutEnabled
      isPGPayEnabled
      isInviteEnabled
      isPayPalEnabled
      isTopUpEnabled
      isNewVisaDesign
      isRegisterBusinessEnabledUSA
      isTransferPamEnabled
      isAnnouncementEnabled
      isBankWithdrawEnabled
      isStripeEnabled
      isCashAppEnabled
      isZelleEnabled
      isAgentPortalEnabled
      isCallCenterEnabled
      isVirtualCardEnabled
      isPayPalRewardsEnabled
      isTransferEnabled
      isMonCashEnabled
      isPlaidEnabled
      isNatCashEnabled
      isUSABankRewardsEnabled
      isPhysicalCardRewardsEnabled
      isBalanceEnabled
      isStripeIssuingEnabled
      isCVCHidden
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalSettings = /* GraphQL */ `
  mutation DeleteGlobalSettings(
    $input: DeleteGlobalSettingsInput!
    $condition: ModelGlobalSettingsConditionInput
  ) {
    deleteGlobalSettings(input: $input, condition: $condition) {
      id
      announcement
      asanaSecret
      operatorId
      monCashFees
      transactionLimitAmount
      monCashWithdrawFees
      zelleFees
      cashAppFees
      cardFees
      transFerFees
      topupFees
      paypalFees
      virtualCardFees
      isStripeCreditCardEnabled
      bankRewardFees
      physicalCardFees
      paymeFees
      agentPhysicalCardFees
      agentCardFees
      agentTransFerFees
      agentTopupFees
      agentPayPalFees
      agentVirtualCardFees
      agentBankRewardFees
      isAPIEnabled
      isVideoTrainingEnabled
      isCryptoPaymentEnabled
      isPaymeEnabled
      isVisaGiftCardEnabled
      isAmazonGiftCardEnabled
      isMasterCardCADEnabled
      isMasterCardUSDEnabled
      isNetflixGiftCardEnabled
      isEbayGiftCardEnabled
      isDivvyCardEnabled
      isMasterCardGiftCardEnabled
      isTPMasterCardEnabled
      isBinanceGiftCardEnabled
      isComplyCubeIdentityEnabled
      isGiftCardDeliveryFeesEnabled
      isCryptoEnabled
      isFlutterWaveCreditCardEnabled
      isFlutterWavePaymeEnabled
      isBinanceHTGiftCardEnabled
      isPaymePremiumEnabled
      isReloadlyGiftCardEnabled
      isUnibankEnabled
      isBUHEnabled
      isTicketDisabled
      isWithdrawEnabled
      isMonCashAutomaticWithdrawEnabled
      isPartner
      isPayPalPayoutEnabled
      isPGPayEnabled
      isInviteEnabled
      isPayPalEnabled
      isTopUpEnabled
      isNewVisaDesign
      isRegisterBusinessEnabledUSA
      isTransferPamEnabled
      isAnnouncementEnabled
      isBankWithdrawEnabled
      isStripeEnabled
      isCashAppEnabled
      isZelleEnabled
      isAgentPortalEnabled
      isCallCenterEnabled
      isVirtualCardEnabled
      isPayPalRewardsEnabled
      isTransferEnabled
      isMonCashEnabled
      isPlaidEnabled
      isNatCashEnabled
      isUSABankRewardsEnabled
      isPhysicalCardRewardsEnabled
      isBalanceEnabled
      isStripeIssuingEnabled
      isCVCHidden
      createdAt
      updatedAt
    }
  }
`;
export const createGiftCard = /* GraphQL */ `
  mutation CreateGiftCard(
    $input: CreateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    createGiftCard(input: $input, condition: $condition) {
      id
      brandId
      giftCardId
      giftCardName
      providerFee
      brandName
      redeemptionInstructions
      minimumAmount
      maximumAmount
      currency
      isPriceFixed
      country
      logoUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateGiftCard = /* GraphQL */ `
  mutation UpdateGiftCard(
    $input: UpdateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    updateGiftCard(input: $input, condition: $condition) {
      id
      brandId
      giftCardId
      giftCardName
      providerFee
      brandName
      redeemptionInstructions
      minimumAmount
      maximumAmount
      currency
      isPriceFixed
      country
      logoUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteGiftCard = /* GraphQL */ `
  mutation DeleteGiftCard(
    $input: DeleteGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    deleteGiftCard(input: $input, condition: $condition) {
      id
      brandId
      giftCardId
      giftCardName
      providerFee
      brandName
      redeemptionInstructions
      minimumAmount
      maximumAmount
      currency
      isPriceFixed
      country
      logoUrl
      createdAt
      updatedAt
    }
  }
`;
export const createBalance = /* GraphQL */ `
  mutation CreateBalance(
    $input: CreateBalanceInput!
    $condition: ModelBalanceConditionInput
  ) {
    createBalance(input: $input, condition: $condition) {
      id
      amount
      gourdes
      dailySpendingAmount
      lastDepositTime
      pendingAmount
      credit
      totalAmountSpent
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateBalance = /* GraphQL */ `
  mutation UpdateBalance(
    $input: UpdateBalanceInput!
    $condition: ModelBalanceConditionInput
  ) {
    updateBalance(input: $input, condition: $condition) {
      id
      amount
      gourdes
      dailySpendingAmount
      lastDepositTime
      pendingAmount
      credit
      totalAmountSpent
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteBalance = /* GraphQL */ `
  mutation DeleteBalance(
    $input: DeleteBalanceInput!
    $condition: ModelBalanceConditionInput
  ) {
    deleteBalance(input: $input, condition: $condition) {
      id
      amount
      gourdes
      dailySpendingAmount
      lastDepositTime
      pendingAmount
      credit
      totalAmountSpent
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createBank = /* GraphQL */ `
  mutation CreateBank(
    $input: CreateBankInput!
    $condition: ModelBankConditionInput
  ) {
    createBank(input: $input, condition: $condition) {
      id
      bankName
      accountId
      bankId
      itemId
      accessToken
      accountName
      accounType
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateBank = /* GraphQL */ `
  mutation UpdateBank(
    $input: UpdateBankInput!
    $condition: ModelBankConditionInput
  ) {
    updateBank(input: $input, condition: $condition) {
      id
      bankName
      accountId
      bankId
      itemId
      accessToken
      accountName
      accounType
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteBank = /* GraphQL */ `
  mutation DeleteBank(
    $input: DeleteBankInput!
    $condition: ModelBankConditionInput
  ) {
    deleteBank(input: $input, condition: $condition) {
      id
      bankName
      accountId
      bankId
      itemId
      accessToken
      accountName
      accounType
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createPayme = /* GraphQL */ `
  mutation CreatePayme(
    $input: CreatePaymeInput!
    $condition: ModelPaymeConditionInput
  ) {
    createPayme(input: $input, condition: $condition) {
      id
      username
      name
      profileImage
      amount
      isPremium
      stripeCheckoutUrl
      paypalCheckoutUrl
      statementDescriptor
      companyName
      facebookLink
      twitterUsername
      instagramUsername
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updatePayme = /* GraphQL */ `
  mutation UpdatePayme(
    $input: UpdatePaymeInput!
    $condition: ModelPaymeConditionInput
  ) {
    updatePayme(input: $input, condition: $condition) {
      id
      username
      name
      profileImage
      amount
      isPremium
      stripeCheckoutUrl
      paypalCheckoutUrl
      statementDescriptor
      companyName
      facebookLink
      twitterUsername
      instagramUsername
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deletePayme = /* GraphQL */ `
  mutation DeletePayme(
    $input: DeletePaymeInput!
    $condition: ModelPaymeConditionInput
  ) {
    deletePayme(input: $input, condition: $condition) {
      id
      username
      name
      profileImage
      amount
      isPremium
      stripeCheckoutUrl
      paypalCheckoutUrl
      statementDescriptor
      companyName
      facebookLink
      twitterUsername
      instagramUsername
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createEarning = /* GraphQL */ `
  mutation CreateEarning(
    $input: CreateEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    createEarning(input: $input, condition: $condition) {
      id
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateEarning = /* GraphQL */ `
  mutation UpdateEarning(
    $input: UpdateEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    updateEarning(input: $input, condition: $condition) {
      id
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteEarning = /* GraphQL */ `
  mutation DeleteEarning(
    $input: DeleteEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    deleteEarning(input: $input, condition: $condition) {
      id
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createPlatformEarning = /* GraphQL */ `
  mutation CreatePlatformEarning(
    $input: CreatePlatformEarningInput!
    $condition: ModelPlatformEarningConditionInput
  ) {
    createPlatformEarning(input: $input, condition: $condition) {
      id
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updatePlatformEarning = /* GraphQL */ `
  mutation UpdatePlatformEarning(
    $input: UpdatePlatformEarningInput!
    $condition: ModelPlatformEarningConditionInput
  ) {
    updatePlatformEarning(input: $input, condition: $condition) {
      id
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deletePlatformEarning = /* GraphQL */ `
  mutation DeletePlatformEarning(
    $input: DeletePlatformEarningInput!
    $condition: ModelPlatformEarningConditionInput
  ) {
    deletePlatformEarning(input: $input, condition: $condition) {
      id
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      message
      announcements
      specialOffer
      reviewSurvey
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      message
      announcements
      specialOffer
      reviewSurvey
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      message
      announcements
      specialOffer
      reviewSurvey
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createCard = /* GraphQL */ `
  mutation CreateCard(
    $input: CreateCardInput!
    $condition: ModelCardConditionInput
  ) {
    createCard(input: $input, condition: $condition) {
      id
      provider
      providerName
      cardHolderId
      cardId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      provider
      providerName
      cardHolderId
      cardId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard(
    $input: DeleteCardInput!
    $condition: ModelCardConditionInput
  ) {
    deleteCard(input: $input, condition: $condition) {
      id
      provider
      providerName
      cardHolderId
      cardId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createPin = /* GraphQL */ `
  mutation CreatePin(
    $input: CreatePinInput!
    $condition: ModelPinConditionInput
  ) {
    createPin(input: $input, condition: $condition) {
      id
      digit
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updatePin = /* GraphQL */ `
  mutation UpdatePin(
    $input: UpdatePinInput!
    $condition: ModelPinConditionInput
  ) {
    updatePin(input: $input, condition: $condition) {
      id
      digit
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deletePin = /* GraphQL */ `
  mutation DeletePin(
    $input: DeletePinInput!
    $condition: ModelPinConditionInput
  ) {
    deletePin(input: $input, condition: $condition) {
      id
      digit
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createPrepaidUser = /* GraphQL */ `
  mutation CreatePrepaidUser(
    $input: CreatePrepaidUserInput!
    $condition: ModelPrepaidUserConditionInput
  ) {
    createPrepaidUser(input: $input, condition: $condition) {
      id
      identityVerificationId
      isActive
      cardHolderId
      stripeCustomerId
      cardId
      balance
      pendingAmount
      isBanned
      privateNote
      isPinEnabled
      isLocked
      isVerified
      fullName
      secretKey
      prepaidUrl
      dailySpendingAmount
      lastDepositTime
      email
      agentID
      phone
      birthDay
      country
      line1
      line2
      city
      state
      postal_code
      OrderHistories {
        items {
          id
          description
          orderId
          sender
          receiver
          platform
          redirectUrl
          shopId
          products
          shippingRate
          paymentId
          phone
          metadata
          customerFullName
          shippingAddress
          isDelivered
          newBalance
          shippingStatus
          deliveredAt
          paymentStatus
          channel
          totalPrice
          paymentMethod
          deliveryMethod
          customerId
          successUrl
          errorUrl
          redeemptionInstructions
          receiptLogo
          isGiftCardCompleted
          giftCardId
          externalTransactionId
          quantity
          fee
          cardUrl
          operatorId
          gourdes
          transactionType
          status
          amount
          userFee
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePrepaidUser = /* GraphQL */ `
  mutation UpdatePrepaidUser(
    $input: UpdatePrepaidUserInput!
    $condition: ModelPrepaidUserConditionInput
  ) {
    updatePrepaidUser(input: $input, condition: $condition) {
      id
      identityVerificationId
      isActive
      cardHolderId
      stripeCustomerId
      cardId
      balance
      pendingAmount
      isBanned
      privateNote
      isPinEnabled
      isLocked
      isVerified
      fullName
      secretKey
      prepaidUrl
      dailySpendingAmount
      lastDepositTime
      email
      agentID
      phone
      birthDay
      country
      line1
      line2
      city
      state
      postal_code
      OrderHistories {
        items {
          id
          description
          orderId
          sender
          receiver
          platform
          redirectUrl
          shopId
          products
          shippingRate
          paymentId
          phone
          metadata
          customerFullName
          shippingAddress
          isDelivered
          newBalance
          shippingStatus
          deliveredAt
          paymentStatus
          channel
          totalPrice
          paymentMethod
          deliveryMethod
          customerId
          successUrl
          errorUrl
          redeemptionInstructions
          receiptLogo
          isGiftCardCompleted
          giftCardId
          externalTransactionId
          quantity
          fee
          cardUrl
          operatorId
          gourdes
          transactionType
          status
          amount
          userFee
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePrepaidUser = /* GraphQL */ `
  mutation DeletePrepaidUser(
    $input: DeletePrepaidUserInput!
    $condition: ModelPrepaidUserConditionInput
  ) {
    deletePrepaidUser(input: $input, condition: $condition) {
      id
      identityVerificationId
      isActive
      cardHolderId
      stripeCustomerId
      cardId
      balance
      pendingAmount
      isBanned
      privateNote
      isPinEnabled
      isLocked
      isVerified
      fullName
      secretKey
      prepaidUrl
      dailySpendingAmount
      lastDepositTime
      email
      agentID
      phone
      birthDay
      country
      line1
      line2
      city
      state
      postal_code
      OrderHistories {
        items {
          id
          description
          orderId
          sender
          receiver
          platform
          redirectUrl
          shopId
          products
          shippingRate
          paymentId
          phone
          metadata
          customerFullName
          shippingAddress
          isDelivered
          newBalance
          shippingStatus
          deliveredAt
          paymentStatus
          channel
          totalPrice
          paymentMethod
          deliveryMethod
          customerId
          successUrl
          errorUrl
          redeemptionInstructions
          receiptLogo
          isGiftCardCompleted
          giftCardId
          externalTransactionId
          quantity
          fee
          cardUrl
          operatorId
          gourdes
          transactionType
          status
          amount
          userFee
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      identityVerificationId
      complyCubeClientId
      isActive
      isAgent
      isAgentPortalEnabled
      isPartner
      isBanned
      isPinEnabled
      isLocked
      isVerified
      isPaymeEnabled
      isVideoTrainingEnabled
      isPaymePremiumEnabled
      isPayPalEnabled
      isAccountLimited
      isFraud
      isCreditCardEnabled
      isFlutterWaveCreditCardEnabled
      isFlutterWavePaymeEnabled
      isVirtualCardEnabled
      isInviteEnabled
      isStripeIssuingEnabled
      isTopUpEnabled
      isTransferPamEnabled
      isWithdrawEnabled
      isMonCashAutomaticWithdrawEnabled
      isComplyCubeIdentityEnabled
      isPhoneVerified
      isReloadlyGiftCardEnabled
      isEmailVerified
      isTwoFactorRequired
      isTwoFactorActivated
      isPayPalRewardsEnabled
      isTransferEnabled
      isUSABankRewardsEnabled
      isPhysicalCardRewardsEnabled
      isCallCenterEnabled
      isCryptoEnabled
      isBalanceEnabled
      isCashAppEnabled
      isUnibankEnabled
      isBUHEnabled
      isZelleEnabled
      isTicketDisabled
      isOnboardingCompleted
      isMonCashEnabled
      isTPMasterCardEnabled
      isNatCashEnabledc
      isPlaidEnabled
      isPayPalPayoutEnabled
      isDivvyCardEnabled
      isNewVisaDesign
      isPGPayEnabled
      isBankWithdrawEnabled
      isCryptoPaymentEnabled
      isRegisterBusinessEnabledUSA
      isStripeEnabled
      isAPIEnabled
      gender
      referenceCode
      referenceDiscountAmount
      isAffiliateEnabled
      profileImageUrl
      privateNote
      customerID
      tryPinCount
      dailyLimitAmount
      cardHolderId
      language
      last4DigitsPrimaryCard
      pgPayMerchantName
      pgPayReturnUrl
      pgPayQRCodeUrl
      asanaSectionId
      asanaUserId
      firebaseUid
      cardId
      firstName
      lastName
      fullName
      asanUserId
      stripePaymentLink
      industry
      jobTitle
      company
      stripeCustomerId
      stripeAccountId
      apiPublicKey
      apiSecretKey
      referalId
      crispUserId
      ftwCardHolderId
      cardQuantity
      agentSubscriptionStart
      agentSubscriptionEnd
      blueCardSubscriptionStart
      blueCardSubscriptionEnd
      ip
      emailError
      emailErrorDescription
      tpCardHolderId
      divvyCardHolderId
      createdAt
      updatedAt
      email
      externalEmail
      phone
      birthDay
      country
      countryCode
      line1
      line2
      city
      state
      postal_code
      Notification {
        id
        message
        announcements
        specialOffer
        reviewSurvey
        userID
        createdAt
        updatedAt
      }
      OrderHistories {
        items {
          id
          description
          orderId
          sender
          receiver
          platform
          redirectUrl
          shopId
          products
          shippingRate
          paymentId
          phone
          metadata
          customerFullName
          shippingAddress
          isDelivered
          newBalance
          shippingStatus
          deliveredAt
          paymentStatus
          channel
          totalPrice
          paymentMethod
          deliveryMethod
          customerId
          successUrl
          errorUrl
          redeemptionInstructions
          receiptLogo
          isGiftCardCompleted
          giftCardId
          externalTransactionId
          quantity
          fee
          cardUrl
          operatorId
          gourdes
          transactionType
          status
          amount
          userFee
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Balance {
        id
        amount
        gourdes
        dailySpendingAmount
        lastDepositTime
        pendingAmount
        credit
        totalAmountSpent
        userID
        createdAt
        updatedAt
      }
      Payme {
        id
        username
        name
        profileImage
        amount
        isPremium
        stripeCheckoutUrl
        paypalCheckoutUrl
        statementDescriptor
        companyName
        facebookLink
        twitterUsername
        instagramUsername
        userID
        createdAt
        updatedAt
      }
      Log {
        id
        ip
        browserId
        type
        author
        isFraud
        isAdmin
        adminEmail
        name
        country
        city
        userID
        createdAt
        updatedAt
      }
      Settings {
        id
        cardFees
        virtualCardFees
        transFerFees
        paypalFees
        topUpFees
        bankRewardFees
        physicalCardFees
        userID
        createdAt
        updatedAt
      }
      Earning {
        id
        amount
        userID
        createdAt
        updatedAt
      }
      Card {
        items {
          id
          provider
          providerName
          cardHolderId
          cardId
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      VirtualCards {
        items {
          id
          description
          orderId
          sender
          deliveryMethod
          deliveryLink
          rewardsId
          recipientEmail
          recipientName
          cardType
          paymentFee
          recipientPhone
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      addresses {
        items {
          id
          name
          street
          city
          state
          zip
          phone
          country
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Customers {
        items {
          id
          email
          fullName
          phoneNumber
          line1
          line2
          city
          country
          state
          postal_code
          notes
          firstName
          lastName
          password
          shopId
          defaultAddress
          lastOrderId
          lastOrderDate
          totalSpent
          ordersCount
          acceptsMarketing
          taxExempt
          verifiedEmail
          addressId
          noteAttributes
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Stores {
        items {
          id
          storeName
          phoneNumber
          line1
          line2
          storeStatus
          storeCategory
          city
          country
          state
          postal_code
          notes
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Favorites {
        items {
          id
          phoneNumber
          operatorName
          amount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      identityVerificationId
      complyCubeClientId
      isActive
      isAgent
      isAgentPortalEnabled
      isPartner
      isBanned
      isPinEnabled
      isLocked
      isVerified
      isPaymeEnabled
      isVideoTrainingEnabled
      isPaymePremiumEnabled
      isPayPalEnabled
      isAccountLimited
      isFraud
      isCreditCardEnabled
      isFlutterWaveCreditCardEnabled
      isFlutterWavePaymeEnabled
      isVirtualCardEnabled
      isInviteEnabled
      isStripeIssuingEnabled
      isTopUpEnabled
      isTransferPamEnabled
      isWithdrawEnabled
      isMonCashAutomaticWithdrawEnabled
      isComplyCubeIdentityEnabled
      isPhoneVerified
      isReloadlyGiftCardEnabled
      isEmailVerified
      isTwoFactorRequired
      isTwoFactorActivated
      isPayPalRewardsEnabled
      isTransferEnabled
      isUSABankRewardsEnabled
      isPhysicalCardRewardsEnabled
      isCallCenterEnabled
      isCryptoEnabled
      isBalanceEnabled
      isCashAppEnabled
      isUnibankEnabled
      isBUHEnabled
      isZelleEnabled
      isTicketDisabled
      isOnboardingCompleted
      isMonCashEnabled
      isTPMasterCardEnabled
      isNatCashEnabledc
      isPlaidEnabled
      isPayPalPayoutEnabled
      isDivvyCardEnabled
      isNewVisaDesign
      isPGPayEnabled
      isBankWithdrawEnabled
      isCryptoPaymentEnabled
      isRegisterBusinessEnabledUSA
      isStripeEnabled
      isAPIEnabled
      gender
      referenceCode
      referenceDiscountAmount
      isAffiliateEnabled
      profileImageUrl
      privateNote
      customerID
      tryPinCount
      dailyLimitAmount
      cardHolderId
      language
      last4DigitsPrimaryCard
      pgPayMerchantName
      pgPayReturnUrl
      pgPayQRCodeUrl
      asanaSectionId
      asanaUserId
      firebaseUid
      cardId
      firstName
      lastName
      fullName
      asanUserId
      stripePaymentLink
      industry
      jobTitle
      company
      stripeCustomerId
      stripeAccountId
      apiPublicKey
      apiSecretKey
      referalId
      crispUserId
      ftwCardHolderId
      cardQuantity
      agentSubscriptionStart
      agentSubscriptionEnd
      blueCardSubscriptionStart
      blueCardSubscriptionEnd
      ip
      emailError
      emailErrorDescription
      tpCardHolderId
      divvyCardHolderId
      createdAt
      updatedAt
      email
      externalEmail
      phone
      birthDay
      country
      countryCode
      line1
      line2
      city
      state
      postal_code
      Notification {
        id
        message
        announcements
        specialOffer
        reviewSurvey
        userID
        createdAt
        updatedAt
      }
      OrderHistories {
        items {
          id
          description
          orderId
          sender
          receiver
          platform
          redirectUrl
          shopId
          products
          shippingRate
          paymentId
          phone
          metadata
          customerFullName
          shippingAddress
          isDelivered
          newBalance
          shippingStatus
          deliveredAt
          paymentStatus
          channel
          totalPrice
          paymentMethod
          deliveryMethod
          customerId
          successUrl
          errorUrl
          redeemptionInstructions
          receiptLogo
          isGiftCardCompleted
          giftCardId
          externalTransactionId
          quantity
          fee
          cardUrl
          operatorId
          gourdes
          transactionType
          status
          amount
          userFee
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Balance {
        id
        amount
        gourdes
        dailySpendingAmount
        lastDepositTime
        pendingAmount
        credit
        totalAmountSpent
        userID
        createdAt
        updatedAt
      }
      Payme {
        id
        username
        name
        profileImage
        amount
        isPremium
        stripeCheckoutUrl
        paypalCheckoutUrl
        statementDescriptor
        companyName
        facebookLink
        twitterUsername
        instagramUsername
        userID
        createdAt
        updatedAt
      }
      Log {
        id
        ip
        browserId
        type
        author
        isFraud
        isAdmin
        adminEmail
        name
        country
        city
        userID
        createdAt
        updatedAt
      }
      Settings {
        id
        cardFees
        virtualCardFees
        transFerFees
        paypalFees
        topUpFees
        bankRewardFees
        physicalCardFees
        userID
        createdAt
        updatedAt
      }
      Earning {
        id
        amount
        userID
        createdAt
        updatedAt
      }
      Card {
        items {
          id
          provider
          providerName
          cardHolderId
          cardId
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      VirtualCards {
        items {
          id
          description
          orderId
          sender
          deliveryMethod
          deliveryLink
          rewardsId
          recipientEmail
          recipientName
          cardType
          paymentFee
          recipientPhone
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      addresses {
        items {
          id
          name
          street
          city
          state
          zip
          phone
          country
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Customers {
        items {
          id
          email
          fullName
          phoneNumber
          line1
          line2
          city
          country
          state
          postal_code
          notes
          firstName
          lastName
          password
          shopId
          defaultAddress
          lastOrderId
          lastOrderDate
          totalSpent
          ordersCount
          acceptsMarketing
          taxExempt
          verifiedEmail
          addressId
          noteAttributes
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Stores {
        items {
          id
          storeName
          phoneNumber
          line1
          line2
          storeStatus
          storeCategory
          city
          country
          state
          postal_code
          notes
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Favorites {
        items {
          id
          phoneNumber
          operatorName
          amount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      identityVerificationId
      complyCubeClientId
      isActive
      isAgent
      isAgentPortalEnabled
      isPartner
      isBanned
      isPinEnabled
      isLocked
      isVerified
      isPaymeEnabled
      isVideoTrainingEnabled
      isPaymePremiumEnabled
      isPayPalEnabled
      isAccountLimited
      isFraud
      isCreditCardEnabled
      isFlutterWaveCreditCardEnabled
      isFlutterWavePaymeEnabled
      isVirtualCardEnabled
      isInviteEnabled
      isStripeIssuingEnabled
      isTopUpEnabled
      isTransferPamEnabled
      isWithdrawEnabled
      isMonCashAutomaticWithdrawEnabled
      isComplyCubeIdentityEnabled
      isPhoneVerified
      isReloadlyGiftCardEnabled
      isEmailVerified
      isTwoFactorRequired
      isTwoFactorActivated
      isPayPalRewardsEnabled
      isTransferEnabled
      isUSABankRewardsEnabled
      isPhysicalCardRewardsEnabled
      isCallCenterEnabled
      isCryptoEnabled
      isBalanceEnabled
      isCashAppEnabled
      isUnibankEnabled
      isBUHEnabled
      isZelleEnabled
      isTicketDisabled
      isOnboardingCompleted
      isMonCashEnabled
      isTPMasterCardEnabled
      isNatCashEnabledc
      isPlaidEnabled
      isPayPalPayoutEnabled
      isDivvyCardEnabled
      isNewVisaDesign
      isPGPayEnabled
      isBankWithdrawEnabled
      isCryptoPaymentEnabled
      isRegisterBusinessEnabledUSA
      isStripeEnabled
      isAPIEnabled
      gender
      referenceCode
      referenceDiscountAmount
      isAffiliateEnabled
      profileImageUrl
      privateNote
      customerID
      tryPinCount
      dailyLimitAmount
      cardHolderId
      language
      last4DigitsPrimaryCard
      pgPayMerchantName
      pgPayReturnUrl
      pgPayQRCodeUrl
      asanaSectionId
      asanaUserId
      firebaseUid
      cardId
      firstName
      lastName
      fullName
      asanUserId
      stripePaymentLink
      industry
      jobTitle
      company
      stripeCustomerId
      stripeAccountId
      apiPublicKey
      apiSecretKey
      referalId
      crispUserId
      ftwCardHolderId
      cardQuantity
      agentSubscriptionStart
      agentSubscriptionEnd
      blueCardSubscriptionStart
      blueCardSubscriptionEnd
      ip
      emailError
      emailErrorDescription
      tpCardHolderId
      divvyCardHolderId
      createdAt
      updatedAt
      email
      externalEmail
      phone
      birthDay
      country
      countryCode
      line1
      line2
      city
      state
      postal_code
      Notification {
        id
        message
        announcements
        specialOffer
        reviewSurvey
        userID
        createdAt
        updatedAt
      }
      OrderHistories {
        items {
          id
          description
          orderId
          sender
          receiver
          platform
          redirectUrl
          shopId
          products
          shippingRate
          paymentId
          phone
          metadata
          customerFullName
          shippingAddress
          isDelivered
          newBalance
          shippingStatus
          deliveredAt
          paymentStatus
          channel
          totalPrice
          paymentMethod
          deliveryMethod
          customerId
          successUrl
          errorUrl
          redeemptionInstructions
          receiptLogo
          isGiftCardCompleted
          giftCardId
          externalTransactionId
          quantity
          fee
          cardUrl
          operatorId
          gourdes
          transactionType
          status
          amount
          userFee
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Balance {
        id
        amount
        gourdes
        dailySpendingAmount
        lastDepositTime
        pendingAmount
        credit
        totalAmountSpent
        userID
        createdAt
        updatedAt
      }
      Payme {
        id
        username
        name
        profileImage
        amount
        isPremium
        stripeCheckoutUrl
        paypalCheckoutUrl
        statementDescriptor
        companyName
        facebookLink
        twitterUsername
        instagramUsername
        userID
        createdAt
        updatedAt
      }
      Log {
        id
        ip
        browserId
        type
        author
        isFraud
        isAdmin
        adminEmail
        name
        country
        city
        userID
        createdAt
        updatedAt
      }
      Settings {
        id
        cardFees
        virtualCardFees
        transFerFees
        paypalFees
        topUpFees
        bankRewardFees
        physicalCardFees
        userID
        createdAt
        updatedAt
      }
      Earning {
        id
        amount
        userID
        createdAt
        updatedAt
      }
      Card {
        items {
          id
          provider
          providerName
          cardHolderId
          cardId
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      VirtualCards {
        items {
          id
          description
          orderId
          sender
          deliveryMethod
          deliveryLink
          rewardsId
          recipientEmail
          recipientName
          cardType
          paymentFee
          recipientPhone
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      addresses {
        items {
          id
          name
          street
          city
          state
          zip
          phone
          country
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Customers {
        items {
          id
          email
          fullName
          phoneNumber
          line1
          line2
          city
          country
          state
          postal_code
          notes
          firstName
          lastName
          password
          shopId
          defaultAddress
          lastOrderId
          lastOrderDate
          totalSpent
          ordersCount
          acceptsMarketing
          taxExempt
          verifiedEmail
          addressId
          noteAttributes
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Stores {
        items {
          id
          storeName
          phoneNumber
          line1
          line2
          storeStatus
          storeCategory
          city
          country
          state
          postal_code
          notes
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      Favorites {
        items {
          id
          phoneNumber
          operatorName
          amount
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zip
      phone
      country
      user {
        id
        identityVerificationId
        complyCubeClientId
        isActive
        isAgent
        isAgentPortalEnabled
        isPartner
        isBanned
        isPinEnabled
        isLocked
        isVerified
        isPaymeEnabled
        isVideoTrainingEnabled
        isPaymePremiumEnabled
        isPayPalEnabled
        isAccountLimited
        isFraud
        isCreditCardEnabled
        isFlutterWaveCreditCardEnabled
        isFlutterWavePaymeEnabled
        isVirtualCardEnabled
        isInviteEnabled
        isStripeIssuingEnabled
        isTopUpEnabled
        isTransferPamEnabled
        isWithdrawEnabled
        isMonCashAutomaticWithdrawEnabled
        isComplyCubeIdentityEnabled
        isPhoneVerified
        isReloadlyGiftCardEnabled
        isEmailVerified
        isTwoFactorRequired
        isTwoFactorActivated
        isPayPalRewardsEnabled
        isTransferEnabled
        isUSABankRewardsEnabled
        isPhysicalCardRewardsEnabled
        isCallCenterEnabled
        isCryptoEnabled
        isBalanceEnabled
        isCashAppEnabled
        isUnibankEnabled
        isBUHEnabled
        isZelleEnabled
        isTicketDisabled
        isOnboardingCompleted
        isMonCashEnabled
        isTPMasterCardEnabled
        isNatCashEnabledc
        isPlaidEnabled
        isPayPalPayoutEnabled
        isDivvyCardEnabled
        isNewVisaDesign
        isPGPayEnabled
        isBankWithdrawEnabled
        isCryptoPaymentEnabled
        isRegisterBusinessEnabledUSA
        isStripeEnabled
        isAPIEnabled
        gender
        referenceCode
        referenceDiscountAmount
        isAffiliateEnabled
        profileImageUrl
        privateNote
        customerID
        tryPinCount
        dailyLimitAmount
        cardHolderId
        language
        last4DigitsPrimaryCard
        pgPayMerchantName
        pgPayReturnUrl
        pgPayQRCodeUrl
        asanaSectionId
        asanaUserId
        firebaseUid
        cardId
        firstName
        lastName
        fullName
        asanUserId
        stripePaymentLink
        industry
        jobTitle
        company
        stripeCustomerId
        stripeAccountId
        apiPublicKey
        apiSecretKey
        referalId
        crispUserId
        ftwCardHolderId
        cardQuantity
        agentSubscriptionStart
        agentSubscriptionEnd
        blueCardSubscriptionStart
        blueCardSubscriptionEnd
        ip
        emailError
        emailErrorDescription
        tpCardHolderId
        divvyCardHolderId
        createdAt
        updatedAt
        email
        externalEmail
        phone
        birthDay
        country
        countryCode
        line1
        line2
        city
        state
        postal_code
        Notification {
          id
          message
          announcements
          specialOffer
          reviewSurvey
          userID
          createdAt
          updatedAt
        }
        OrderHistories {
          nextToken
        }
        Balance {
          id
          amount
          gourdes
          dailySpendingAmount
          lastDepositTime
          pendingAmount
          credit
          totalAmountSpent
          userID
          createdAt
          updatedAt
        }
        Payme {
          id
          username
          name
          profileImage
          amount
          isPremium
          stripeCheckoutUrl
          paypalCheckoutUrl
          statementDescriptor
          companyName
          facebookLink
          twitterUsername
          instagramUsername
          userID
          createdAt
          updatedAt
        }
        Log {
          id
          ip
          browserId
          type
          author
          isFraud
          isAdmin
          adminEmail
          name
          country
          city
          userID
          createdAt
          updatedAt
        }
        Settings {
          id
          cardFees
          virtualCardFees
          transFerFees
          paypalFees
          topUpFees
          bankRewardFees
          physicalCardFees
          userID
          createdAt
          updatedAt
        }
        Earning {
          id
          amount
          userID
          createdAt
          updatedAt
        }
        Card {
          nextToken
        }
        VirtualCards {
          nextToken
        }
        addresses {
          nextToken
        }
        Customers {
          nextToken
        }
        Stores {
          nextToken
        }
        Favorites {
          nextToken
        }
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zip
      phone
      country
      user {
        id
        identityVerificationId
        complyCubeClientId
        isActive
        isAgent
        isAgentPortalEnabled
        isPartner
        isBanned
        isPinEnabled
        isLocked
        isVerified
        isPaymeEnabled
        isVideoTrainingEnabled
        isPaymePremiumEnabled
        isPayPalEnabled
        isAccountLimited
        isFraud
        isCreditCardEnabled
        isFlutterWaveCreditCardEnabled
        isFlutterWavePaymeEnabled
        isVirtualCardEnabled
        isInviteEnabled
        isStripeIssuingEnabled
        isTopUpEnabled
        isTransferPamEnabled
        isWithdrawEnabled
        isMonCashAutomaticWithdrawEnabled
        isComplyCubeIdentityEnabled
        isPhoneVerified
        isReloadlyGiftCardEnabled
        isEmailVerified
        isTwoFactorRequired
        isTwoFactorActivated
        isPayPalRewardsEnabled
        isTransferEnabled
        isUSABankRewardsEnabled
        isPhysicalCardRewardsEnabled
        isCallCenterEnabled
        isCryptoEnabled
        isBalanceEnabled
        isCashAppEnabled
        isUnibankEnabled
        isBUHEnabled
        isZelleEnabled
        isTicketDisabled
        isOnboardingCompleted
        isMonCashEnabled
        isTPMasterCardEnabled
        isNatCashEnabledc
        isPlaidEnabled
        isPayPalPayoutEnabled
        isDivvyCardEnabled
        isNewVisaDesign
        isPGPayEnabled
        isBankWithdrawEnabled
        isCryptoPaymentEnabled
        isRegisterBusinessEnabledUSA
        isStripeEnabled
        isAPIEnabled
        gender
        referenceCode
        referenceDiscountAmount
        isAffiliateEnabled
        profileImageUrl
        privateNote
        customerID
        tryPinCount
        dailyLimitAmount
        cardHolderId
        language
        last4DigitsPrimaryCard
        pgPayMerchantName
        pgPayReturnUrl
        pgPayQRCodeUrl
        asanaSectionId
        asanaUserId
        firebaseUid
        cardId
        firstName
        lastName
        fullName
        asanUserId
        stripePaymentLink
        industry
        jobTitle
        company
        stripeCustomerId
        stripeAccountId
        apiPublicKey
        apiSecretKey
        referalId
        crispUserId
        ftwCardHolderId
        cardQuantity
        agentSubscriptionStart
        agentSubscriptionEnd
        blueCardSubscriptionStart
        blueCardSubscriptionEnd
        ip
        emailError
        emailErrorDescription
        tpCardHolderId
        divvyCardHolderId
        createdAt
        updatedAt
        email
        externalEmail
        phone
        birthDay
        country
        countryCode
        line1
        line2
        city
        state
        postal_code
        Notification {
          id
          message
          announcements
          specialOffer
          reviewSurvey
          userID
          createdAt
          updatedAt
        }
        OrderHistories {
          nextToken
        }
        Balance {
          id
          amount
          gourdes
          dailySpendingAmount
          lastDepositTime
          pendingAmount
          credit
          totalAmountSpent
          userID
          createdAt
          updatedAt
        }
        Payme {
          id
          username
          name
          profileImage
          amount
          isPremium
          stripeCheckoutUrl
          paypalCheckoutUrl
          statementDescriptor
          companyName
          facebookLink
          twitterUsername
          instagramUsername
          userID
          createdAt
          updatedAt
        }
        Log {
          id
          ip
          browserId
          type
          author
          isFraud
          isAdmin
          adminEmail
          name
          country
          city
          userID
          createdAt
          updatedAt
        }
        Settings {
          id
          cardFees
          virtualCardFees
          transFerFees
          paypalFees
          topUpFees
          bankRewardFees
          physicalCardFees
          userID
          createdAt
          updatedAt
        }
        Earning {
          id
          amount
          userID
          createdAt
          updatedAt
        }
        Card {
          nextToken
        }
        VirtualCards {
          nextToken
        }
        addresses {
          nextToken
        }
        Customers {
          nextToken
        }
        Stores {
          nextToken
        }
        Favorites {
          nextToken
        }
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zip
      phone
      country
      user {
        id
        identityVerificationId
        complyCubeClientId
        isActive
        isAgent
        isAgentPortalEnabled
        isPartner
        isBanned
        isPinEnabled
        isLocked
        isVerified
        isPaymeEnabled
        isVideoTrainingEnabled
        isPaymePremiumEnabled
        isPayPalEnabled
        isAccountLimited
        isFraud
        isCreditCardEnabled
        isFlutterWaveCreditCardEnabled
        isFlutterWavePaymeEnabled
        isVirtualCardEnabled
        isInviteEnabled
        isStripeIssuingEnabled
        isTopUpEnabled
        isTransferPamEnabled
        isWithdrawEnabled
        isMonCashAutomaticWithdrawEnabled
        isComplyCubeIdentityEnabled
        isPhoneVerified
        isReloadlyGiftCardEnabled
        isEmailVerified
        isTwoFactorRequired
        isTwoFactorActivated
        isPayPalRewardsEnabled
        isTransferEnabled
        isUSABankRewardsEnabled
        isPhysicalCardRewardsEnabled
        isCallCenterEnabled
        isCryptoEnabled
        isBalanceEnabled
        isCashAppEnabled
        isUnibankEnabled
        isBUHEnabled
        isZelleEnabled
        isTicketDisabled
        isOnboardingCompleted
        isMonCashEnabled
        isTPMasterCardEnabled
        isNatCashEnabledc
        isPlaidEnabled
        isPayPalPayoutEnabled
        isDivvyCardEnabled
        isNewVisaDesign
        isPGPayEnabled
        isBankWithdrawEnabled
        isCryptoPaymentEnabled
        isRegisterBusinessEnabledUSA
        isStripeEnabled
        isAPIEnabled
        gender
        referenceCode
        referenceDiscountAmount
        isAffiliateEnabled
        profileImageUrl
        privateNote
        customerID
        tryPinCount
        dailyLimitAmount
        cardHolderId
        language
        last4DigitsPrimaryCard
        pgPayMerchantName
        pgPayReturnUrl
        pgPayQRCodeUrl
        asanaSectionId
        asanaUserId
        firebaseUid
        cardId
        firstName
        lastName
        fullName
        asanUserId
        stripePaymentLink
        industry
        jobTitle
        company
        stripeCustomerId
        stripeAccountId
        apiPublicKey
        apiSecretKey
        referalId
        crispUserId
        ftwCardHolderId
        cardQuantity
        agentSubscriptionStart
        agentSubscriptionEnd
        blueCardSubscriptionStart
        blueCardSubscriptionEnd
        ip
        emailError
        emailErrorDescription
        tpCardHolderId
        divvyCardHolderId
        createdAt
        updatedAt
        email
        externalEmail
        phone
        birthDay
        country
        countryCode
        line1
        line2
        city
        state
        postal_code
        Notification {
          id
          message
          announcements
          specialOffer
          reviewSurvey
          userID
          createdAt
          updatedAt
        }
        OrderHistories {
          nextToken
        }
        Balance {
          id
          amount
          gourdes
          dailySpendingAmount
          lastDepositTime
          pendingAmount
          credit
          totalAmountSpent
          userID
          createdAt
          updatedAt
        }
        Payme {
          id
          username
          name
          profileImage
          amount
          isPremium
          stripeCheckoutUrl
          paypalCheckoutUrl
          statementDescriptor
          companyName
          facebookLink
          twitterUsername
          instagramUsername
          userID
          createdAt
          updatedAt
        }
        Log {
          id
          ip
          browserId
          type
          author
          isFraud
          isAdmin
          adminEmail
          name
          country
          city
          userID
          createdAt
          updatedAt
        }
        Settings {
          id
          cardFees
          virtualCardFees
          transFerFees
          paypalFees
          topUpFees
          bankRewardFees
          physicalCardFees
          userID
          createdAt
          updatedAt
        }
        Earning {
          id
          amount
          userID
          createdAt
          updatedAt
        }
        Card {
          nextToken
        }
        VirtualCards {
          nextToken
        }
        addresses {
          nextToken
        }
        Customers {
          nextToken
        }
        Stores {
          nextToken
        }
        Favorites {
          nextToken
        }
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createOrderHistory = /* GraphQL */ `
  mutation CreateOrderHistory(
    $input: CreateOrderHistoryInput!
    $condition: ModelOrderHistoryConditionInput
  ) {
    createOrderHistory(input: $input, condition: $condition) {
      id
      description
      orderId
      sender
      receiver
      platform
      redirectUrl
      shopId
      products
      shippingRate
      paymentId
      phone
      metadata
      customerFullName
      shippingAddress
      isDelivered
      newBalance
      shippingStatus
      deliveredAt
      paymentStatus
      channel
      totalPrice
      paymentMethod
      deliveryMethod
      customerId
      successUrl
      errorUrl
      redeemptionInstructions
      receiptLogo
      isGiftCardCompleted
      giftCardId
      externalTransactionId
      quantity
      fee
      cardUrl
      operatorId
      gourdes
      transactionType
      status
      amount
      userFee
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderHistory = /* GraphQL */ `
  mutation UpdateOrderHistory(
    $input: UpdateOrderHistoryInput!
    $condition: ModelOrderHistoryConditionInput
  ) {
    updateOrderHistory(input: $input, condition: $condition) {
      id
      description
      orderId
      sender
      receiver
      platform
      redirectUrl
      shopId
      products
      shippingRate
      paymentId
      phone
      metadata
      customerFullName
      shippingAddress
      isDelivered
      newBalance
      shippingStatus
      deliveredAt
      paymentStatus
      channel
      totalPrice
      paymentMethod
      deliveryMethod
      customerId
      successUrl
      errorUrl
      redeemptionInstructions
      receiptLogo
      isGiftCardCompleted
      giftCardId
      externalTransactionId
      quantity
      fee
      cardUrl
      operatorId
      gourdes
      transactionType
      status
      amount
      userFee
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderHistory = /* GraphQL */ `
  mutation DeleteOrderHistory(
    $input: DeleteOrderHistoryInput!
    $condition: ModelOrderHistoryConditionInput
  ) {
    deleteOrderHistory(input: $input, condition: $condition) {
      id
      description
      orderId
      sender
      receiver
      platform
      redirectUrl
      shopId
      products
      shippingRate
      paymentId
      phone
      metadata
      customerFullName
      shippingAddress
      isDelivered
      newBalance
      shippingStatus
      deliveredAt
      paymentStatus
      channel
      totalPrice
      paymentMethod
      deliveryMethod
      customerId
      successUrl
      errorUrl
      redeemptionInstructions
      receiptLogo
      isGiftCardCompleted
      giftCardId
      externalTransactionId
      quantity
      fee
      cardUrl
      operatorId
      gourdes
      transactionType
      status
      amount
      userFee
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createMonCashOperatorIntegration = /* GraphQL */ `
  mutation CreateMonCashOperatorIntegration(
    $input: CreateMonCashOperatorIntegrationInput!
    $condition: ModelMonCashOperatorIntegrationConditionInput
  ) {
    createMonCashOperatorIntegration(input: $input, condition: $condition) {
      id
      clientId
      secretKey
      successUrl
      errorUrl
      returnUrl
      operatorName
      maximumNegativeAmount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateMonCashOperatorIntegration = /* GraphQL */ `
  mutation UpdateMonCashOperatorIntegration(
    $input: UpdateMonCashOperatorIntegrationInput!
    $condition: ModelMonCashOperatorIntegrationConditionInput
  ) {
    updateMonCashOperatorIntegration(input: $input, condition: $condition) {
      id
      clientId
      secretKey
      successUrl
      errorUrl
      returnUrl
      operatorName
      maximumNegativeAmount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteMonCashOperatorIntegration = /* GraphQL */ `
  mutation DeleteMonCashOperatorIntegration(
    $input: DeleteMonCashOperatorIntegrationInput!
    $condition: ModelMonCashOperatorIntegrationConditionInput
  ) {
    deleteMonCashOperatorIntegration(input: $input, condition: $condition) {
      id
      clientId
      secretKey
      successUrl
      errorUrl
      returnUrl
      operatorName
      maximumNegativeAmount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createOfflineTransaction = /* GraphQL */ `
  mutation CreateOfflineTransaction(
    $input: CreateOfflineTransactionInput!
    $condition: ModelOfflineTransactionConditionInput
  ) {
    createOfflineTransaction(input: $input, condition: $condition) {
      id
      type
      status
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateOfflineTransaction = /* GraphQL */ `
  mutation UpdateOfflineTransaction(
    $input: UpdateOfflineTransactionInput!
    $condition: ModelOfflineTransactionConditionInput
  ) {
    updateOfflineTransaction(input: $input, condition: $condition) {
      id
      type
      status
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOfflineTransaction = /* GraphQL */ `
  mutation DeleteOfflineTransaction(
    $input: DeleteOfflineTransactionInput!
    $condition: ModelOfflineTransactionConditionInput
  ) {
    deleteOfflineTransaction(input: $input, condition: $condition) {
      id
      type
      status
      amount
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createVirtualCard = /* GraphQL */ `
  mutation CreateVirtualCard(
    $input: CreateVirtualCardInput!
    $condition: ModelVirtualCardConditionInput
  ) {
    createVirtualCard(input: $input, condition: $condition) {
      id
      description
      orderId
      sender
      deliveryMethod
      deliveryLink
      rewardsId
      recipientEmail
      recipientName
      cardType
      paymentFee
      recipientPhone
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateVirtualCard = /* GraphQL */ `
  mutation UpdateVirtualCard(
    $input: UpdateVirtualCardInput!
    $condition: ModelVirtualCardConditionInput
  ) {
    updateVirtualCard(input: $input, condition: $condition) {
      id
      description
      orderId
      sender
      deliveryMethod
      deliveryLink
      rewardsId
      recipientEmail
      recipientName
      cardType
      paymentFee
      recipientPhone
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteVirtualCard = /* GraphQL */ `
  mutation DeleteVirtualCard(
    $input: DeleteVirtualCardInput!
    $condition: ModelVirtualCardConditionInput
  ) {
    deleteVirtualCard(input: $input, condition: $condition) {
      id
      description
      orderId
      sender
      deliveryMethod
      deliveryLink
      rewardsId
      recipientEmail
      recipientName
      cardType
      paymentFee
      recipientPhone
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      email
      fullName
      phoneNumber
      line1
      line2
      city
      country
      state
      postal_code
      notes
      firstName
      lastName
      password
      shopId
      defaultAddress
      lastOrderId
      lastOrderDate
      totalSpent
      ordersCount
      acceptsMarketing
      taxExempt
      verifiedEmail
      addressId
      noteAttributes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      email
      fullName
      phoneNumber
      line1
      line2
      city
      country
      state
      postal_code
      notes
      firstName
      lastName
      password
      shopId
      defaultAddress
      lastOrderId
      lastOrderDate
      totalSpent
      ordersCount
      acceptsMarketing
      taxExempt
      verifiedEmail
      addressId
      noteAttributes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      email
      fullName
      phoneNumber
      line1
      line2
      city
      country
      state
      postal_code
      notes
      firstName
      lastName
      password
      shopId
      defaultAddress
      lastOrderId
      lastOrderDate
      totalSpent
      ordersCount
      acceptsMarketing
      taxExempt
      verifiedEmail
      addressId
      noteAttributes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      businessName
      monCashQRCode
      monCashQRCodeImageUrl
      line1
      line2
      city
      country
      state
      isFraud
      postal_code
      notes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      businessName
      monCashQRCode
      monCashQRCodeImageUrl
      line1
      line2
      city
      country
      state
      isFraud
      postal_code
      notes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      businessName
      monCashQRCode
      monCashQRCodeImageUrl
      line1
      line2
      city
      country
      state
      isFraud
      postal_code
      notes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      alt
      name
      productId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      alt
      name
      productId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      alt
      name
      productId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      title
      storeId
      productId
      productName
      description
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      title
      storeId
      productId
      productName
      description
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      title
      storeId
      productId
      productName
      description
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      storeName
      storeId
      productName
      description
      collectionId
      collectionName
      price
      status
      quantity
      currency
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      storeName
      storeId
      productName
      description
      collectionId
      collectionName
      price
      status
      quantity
      currency
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      storeName
      storeId
      productName
      description
      collectionId
      collectionName
      price
      status
      quantity
      currency
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      storeName
      phoneNumber
      line1
      line2
      storeStatus
      storeCategory
      city
      country
      state
      postal_code
      notes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      storeName
      phoneNumber
      line1
      line2
      storeStatus
      storeCategory
      city
      country
      state
      postal_code
      notes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      storeName
      phoneNumber
      line1
      line2
      storeStatus
      storeCategory
      city
      country
      state
      postal_code
      notes
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      ip
      browserId
      type
      author
      isFraud
      isAdmin
      adminEmail
      name
      country
      city
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      ip
      browserId
      type
      author
      isFraud
      isAdmin
      adminEmail
      name
      country
      city
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      ip
      browserId
      type
      author
      isFraud
      isAdmin
      adminEmail
      name
      country
      city
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createPrivateNote = /* GraphQL */ `
  mutation CreatePrivateNote(
    $input: CreatePrivateNoteInput!
    $condition: ModelPrivateNoteConditionInput
  ) {
    createPrivateNote(input: $input, condition: $condition) {
      id
      text
      author
      authorId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updatePrivateNote = /* GraphQL */ `
  mutation UpdatePrivateNote(
    $input: UpdatePrivateNoteInput!
    $condition: ModelPrivateNoteConditionInput
  ) {
    updatePrivateNote(input: $input, condition: $condition) {
      id
      text
      author
      authorId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deletePrivateNote = /* GraphQL */ `
  mutation DeletePrivateNote(
    $input: DeletePrivateNoteInput!
    $condition: ModelPrivateNoteConditionInput
  ) {
    deletePrivateNote(input: $input, condition: $condition) {
      id
      text
      author
      authorId
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      name
      adminName
      userName
      amount
      adminProfilePic
      userProfilePic
      activityType
      adminUserID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      name
      adminName
      userName
      amount
      adminProfilePic
      userProfilePic
      activityType
      adminUserID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      name
      adminName
      userName
      amount
      adminProfilePic
      userProfilePic
      activityType
      adminUserID
      userID
      createdAt
      updatedAt
    }
  }
`;
