import { createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";
import { ILog } from "@Types";

import { extendedApi } from "@Libs/admin/logs";

const initialState: ILog[] = [];

export const logSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // updateUser: (state, action: PayloadAction<IUserProfile>) => action?.payload,
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addMatcher(
      extendedApi.endpoints.getLogsByUserID.matchFulfilled,
      (state, action) => action?.payload
    );
  },
});

// Action creators are generated for each case reducer function
// export const { updateUser } = logSlice.actions;

export default logSlice.reducer;
