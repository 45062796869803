import { pgRewardsApi } from "@Libs";
import { ILog } from "@Types";

export const extendedApi = pgRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    getLogsByUserID: builder.query<ILog[], string>({
      query: (userID) => `/admin/log?userID=${userID}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetLogsByUserIDQuery } = extendedApi;
