export interface IContact {
  id?: string;
  email?: string;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  monCashQRCode?: string;
  monCashQRCodeImageUrl?: string;
  line1?: string;
  line2?: string;
  city?: string;
  country?: string;
  state?: string;
  isFraud?: boolean;
  postal_code?: string;
  notes?: string;
  userID?: string;
}

export enum IContactIndexName {
  BY_EMAIL = "byContactEmail",
  BY_PHONE = "byContactPhone",
  BY_USER = "byContact",
}
