import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "@Types/Iproduct";

type IInitiaiState = {
  list: IProduct[];
  activeProduct: IProduct;
};

const initialState: IInitiaiState = {
  list: [],
  activeProduct: {},
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductList: (state, action: PayloadAction<IProduct[]>) => {
      state.list = action.payload;
    },

    addProductToList: (state, action: PayloadAction<IProduct>) => {
      state.list.push(action.payload);
    },

    setActiveProduct: (state, action: PayloadAction<IProduct>) => {
      state.activeProduct = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProductList,
  addProductToList,
  setActiveProduct,
} = productSlice.actions;

export default productSlice.reducer;
