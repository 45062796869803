import { GiftCardDelivery } from "./Igiftcard";
import { ISudoMerchant, ISudoTerminal } from "./Isudo";

export interface ICard {
  status: string;
  id: string;
  cardId: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}

export enum CardType {
  BANK = "bank",
  PAYPAL = "paypal",
  PHYSICAL = "physical",
  VIRTUAL = "virtual",
  GIFT_CARD = "giftcard",
}

export enum ICardProvider {
  SUDO_MASTERCARD = "sudo_mastercard",
  SUDO_VISA = "sudo_visa",
  LIVITPAY_MASTERCARD = "livitpay_mastercard",
  BILL_VISA = "bill_visa",
  FLUTTERWAVE = "flutterwave",
  TPCARD = "tpcard",
  STRIPE = "stripe",
  LIVITPAY_VISA = "livitpay_visa",
}

export enum ICardView {
  ADD_FUNDS = "add_funds",
  WITHDRAW_FUNDS = "withdraw_funds",
  VIEW_DETAILS = "view_details",
  PHYSICAL_CARD = "physical_card",
  VIEW_TRANSACTIONS = "view_transactions",
}

export interface IVirtualCard {
  id?: string;
  provider?: string;
  providerId?: string;
  providerAccountId?: string;

  type?: ICardProvider;
  status?: string;
  currency?: string;
  brand?: string;
  userID?: string;
  cardEmail?: string;
  displayName?: string;
  isCardStillActive?: boolean;
  updatedAt?: string;
  createdAt?: string;

  // Deprecated
  description?: string;
  orderId?: string;
  sender?: string;
  deliveryMethod?: string;
  deliveryLink?: string;
  rewardsId?: string;
  recipientEmail?: string;
  recipientName?: string;
  cardType?: string;
  paymentFee?: string;
  recipientPhone?: string;
}

export interface ICardTransaction {
  createdDate: string;
  merchantName: string;
  amount: number;
  status: string;
  transactionId: string;
  description?: string;
}

export interface ICardTransactionProps {
  transactions: Array<ICardTransaction>;
  cardIds: Array<ICard>;
}

export interface ITPCardResponse {
  path?: string;
  status?: "success" | "pending";
  message?: string;
  code?: number;
  data?: {
    holder_id?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile?: string;
    address?: {
      line_1?: string;
      line_2?: null;
      city?: string;
      state?: string;
      country?: string;
      zip?: string;
    };
  };
}

export interface ITPPCardRetrieval {
  path?: string;
  status?: "success" | "pending";
  message?: string;
  secureClientToken?: string;
  data?: {
    uikey?: string;
    brand?: string;
    number?: string;
    cvv?: string;
    pan?: string;
    expiration?: string;
    holder?: {
      id?: string;
      fullname?: string;
      email?: string;
      mobile?: string;
    };
    balance?: {
      available?: number;
      current?: number;
      currency?: string;
    };
  };
}

export interface IFlutterWaveCardResponse {
  status?: string;
  message?: string;
  data?: {
    id?: string;
    account_id?: number;
    amount?: string;
    currency?: string;
    card_pan?: string;
    masked_pan?: string;
    city?: string;
    state?: string;
    address_1?: string;
    address_2?: string;
    zip_code?: string;
    cvv?: string;
    expiration?: string;
    send_to?: null;
    bin_check_name?: null;
    card_type?: string;
    name_on_card?: string;
    created_at?: string;
    is_active?: boolean;
    callback_url?: string;
  };
}

export interface IGlobalCardSetting {
  id?: string;
  isStandardDelivery?: boolean;
  isRegularDelivery?: boolean;
  isExpressDelivery?: boolean;
  isUrgentDelivery?: boolean;
  isInstantDelivery?: boolean;
  defaultDelivery?: GiftCardDelivery;

  // Delivery Fees
  standardDeliveryFee?: number;
  discountedStandardDeliveryFee?: number;
  regularDeliveryFee?: number;
  expressDeliveryFee?: number;
  urgentDeliveryFee?: number;
  instantDeliveryFee?: number;
  discountedInstantDeliveryFee?: number;

  isActivated?: boolean;
  isDepositEnabled?: boolean;
  isWithdrawalEnabled?: boolean;
  type?: ICardProvider;

  displayName?: string;

  fixedDepositFee?: number;
  depositFeePercentage?: number;
  withdrawFeePercentage?: number;
  price?: number;

  textColor?: string;
  backgroundColor?: string;

  minimum?: number;
  maximum?: number;
  platform?: string;
}

export enum ISudoSettlementAccount {
  SUDO_ACCOUNT_SANDBOX = "662013b5b7bfadcf0bc7abfc",
  SUDO_ACCOUNT_LIVE = "66294f3c242a3da09a917920",
}

export enum ISudoTransactionType {
  DECLINED = "authorization.declined",
  CREATED = "transaction.created",
}

export enum ISudoCardStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  FROZEN = "frozen",
  PENDING = "pending",
  CANCELLED = "cancelled",
}

export interface ISudoWebhookTransaction {
  environment: string;
  business: string;
  data: {
    object: {
      business: string;
      customer: string;
      account: string;
      card: string;
      amount: number;
      fee: number;
      vat: number;
      approved: boolean;
      currency: string;
      status: string;
      authorizationMethod: string;
      balanceTransactions: [];
      merchantAmount: number;
      merchantCurrency: string;
      merchant: ISudoMerchant;
      terminal: ISudoTerminal;
      transactionMetadata: {
        channel: string;
        type: string;
        reference: string;
        _id: string;
      };
      pendingRequest: null;
      requestHistory: [
        {
          amount: number;
          currency: string;
          approved: boolean;
          merchantAmount: number;
          merchantCurrency: string;
          reason: string;
          narration: string;
          createdAt: string;
          _id: string;
        }
      ];
      verification: {
        _id: string;
      };
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      _id: string;
      feeDetails: [];
      __v: number;
    };
    _id: string;
  };
  type: ISudoTransactionType;
  pendingWebhook: boolean;
  webhookArchived: boolean;
  createdAt: number;
  _id: string;
  __v: number;
}
