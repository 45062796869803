import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IFooterMenu } from "@Types/Ifooter-menu";
import { IFooterMenuItem } from "@Types/Ifooter-menu-item";
import { IMenuItem } from "@Types/Imenu-item";

type IInitialState = {
  footerMenu: IFooterMenu[];
  footerMenuItems: IFooterMenuItem[];
  homeMenu: IMenuItem[];
  currentMenu: IMenuItem;
  currentFooterMenu: IMenuItem;
};

const initialState: IInitialState = {
  footerMenu: [],
  homeMenu: [],
  footerMenuItems: [],
  currentMenu: {},
  currentFooterMenu: {},
};

export const userSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setFooterMenu: (state, action: PayloadAction<IFooterMenu[]>) => {
      state.footerMenu = action.payload;
    },

    setHomeMenu: (state, action: PayloadAction<IMenuItem[]>) => {
      state.homeMenu = action.payload;
    },

    setFooterMenuItems: (state, action: PayloadAction<IFooterMenuItem[]>) => {
      state.footerMenuItems = action.payload;
    },

    setCurrentMenu: (state, action: PayloadAction<IMenuItem>) => {
      state.currentMenu = action.payload;
    },

    setCurrentFooterMenu: (state, action: PayloadAction<IMenuItem>) => {
      state.currentFooterMenu = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFooterMenu,
  setHomeMenu,
  setFooterMenuItems,
  setCurrentMenu,
  setCurrentFooterMenu,
} = userSlice.actions;

export default userSlice.reducer;
