import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IShop, IShopPermission } from "@Types/Ishop";

type IInitialState = {
  list: IShop[];
  activeShop: IShop;
  currentShop: IShop;
  staffList: IShopPermission[];
  isStoreInitialized: boolean;
};

const initialState: IInitialState = {
  list: [],
  activeShop: {},
  currentShop: {},
  staffList: [],
  isStoreInitialized: false,
};

export const userSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShopList: (state, action: PayloadAction<IShop[]>) => {
      state.list = action.payload;
    },

    addShopToList: (state, action: PayloadAction<IShop>) => {
      state.list.push(action.payload);
    },

    setActiveShop: (state, action: PayloadAction<IShop>) => {
      state.activeShop = action.payload;
    },

    addStaffMemberToList: (state, action: PayloadAction<IShopPermission[]>) => {
      state.staffList = action.payload;
    },

    setCurrentShop: (state, action: PayloadAction<IShop>) => {
      state.currentShop = action.payload;
    },

    setIsStoreInitialized: (state, action: PayloadAction<boolean>) => {
      state.isStoreInitialized = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShopList,
  addShopToList,
  setActiveShop,
  addStaffMemberToList,
  setCurrentShop,
  setIsStoreInitialized,
} = userSlice.actions;

export default userSlice.reducer;
