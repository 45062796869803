import { createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";

import { IIntegration } from "@Types/Iintegration";

const initialState: IIntegration[] = [];

export const userSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setIntegrations: (state, action) => action.payload,
  },
  // extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const { setIntegrations } = userSlice.actions;

export default userSlice.reducer;
