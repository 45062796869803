export enum OrderStatus {
  ALL = "all",
  PENDING = "pending",
  COMPLETE = "completed",
  DELIVERED = "delivered",
  APPROVED = "approved",
  DECLINED = "declined",
  HOLD = "hold",
  EXPIRED = "expired",
  PAID = "paid",
  REFUND = "refunded",
  PROCESSING = "processing",
  ADD_TO_CART = "add_to_cart",
  SHIPPED = "shipped",
  FAILED = "failed",
  CANCELLED = "cancelled",
  OUT_OF_STOCK = "out_of_stock",
}
