import { IFlutterWaveCardResponse, IGender, IUser } from "@Types";
import { v4 as uuidv4 } from "uuid";

export const balance = {
  id: "",
  amount: 0,
  pendingAmount: 0,
  totalAmountSpent: 0,
  userID: "",
  createdAt: "",
  updatedAt: "",
};

export const loan = {
  id: "",
  amount: 0,
  pendingAmount: 0,
  totalAmountSpent: 0,
  userID: "",
  createdAt: "",
  updatedAt: "",

  loanAmount: 0,
  interestRate: 0,
  loanTermInMonths: 1,
  startDate: "",
  dueDate: "",
  outstandingAmount: 0,
  status: "",
};

export const address = {
  line1: "",
  line2: "",
  city: "",
  postal_code: "",
  state: "",
  country: "",
};

export const defaultPayme = {
  id: "",
  username: "",
  name: "",
  profileImage: "",
  companyName: "",
  amount: 0,
  facebookLink: "",
  isPGPayEnabled: false,
  twitterUsername: "",
  instagramUsername: "",
  userID: "",
  createdAt: "",
  updatedAt: "",
  isPaymeEnabled: true,
  isFlutterWavePaymeEnabled: false,
};

export const defaultPrepaid = {
  line1: "",
  createdAt: "",
  fullName: "",
  email: "",
  country: "",
  prepaidUrl: "",
  state: "",
  city: "",
  isActive: false,
  balance: 0,
  updatedAt: "",
  userID: "",
  id: "",
};

export const setting = {
  id: "",
  userID: "",
  cardFees: 4,
  virtualCardFees: 4,
  transFerFees: 1,
  topUpFees: 0,
  paypalFees: 5,
  physicalCardFees: 5,
  bankRewardFees: 5,
};

export const globalSetting = {
  id: "",
  announcement: "",
  operatorId: "",
  operators: [],
  cardFees: 4,
  transFerFees: 1,
  topupFees: 0,
  monCashFees: 6,
  monCashWithdrawFees: 123,
  cashAppFees: 5,
  zelleFees: 5,
  paypalFees: 4,
  virtualCardFees: 8,
  bankRewardFees: 5,
  physicalCardFees: 5,
  paymeFees: 5,
  agentPhysicalCardFees: 5,
  agentCardFees: 4,
  agentTransFerFees: 0,
  agentTopupFees: 0,
  agentPayPalFees: 5,
  agentVirtualCardFees: 4,
  agentBankRewardFees: 5,

  // Feature
  isPaymeEnabled: false,
  isAPIEnabled: false,
  isBankWithdrawEnabled: false,
  isInviteEnabled: false,
  isAgentPortalEnabled: false,
  isAnnouncementEnabled: false,
  isWithdrawEnabled: false,
  isNatCashEnabled: false,
  isPayPalEnabled: false,
  isPartner: false,
  isVirtualCardEnabled: false,
  isCryptoEnabled: false,
  isMonCashEnabled: false,
  isRegisterBusinessEnabledUSA: false,
  isNewVisaDesign: false,
  isTransferPamEnabled: false,
  isPayPalPayoutEnabled: false,
  isPayPalRewardsEnabled: false,
  isTransferEnabled: false,
  isUSABankRewardsEnabled: false,
  isPhysicalCardRewardsEnabled: false,
  isBalanceEnabled: false,
  isTopUpEnabled: false,
  isStripeEnabled: false,
  isStripeIssuingEnabled: false,
};

export const earning = {
  id: "",
  amount: "",
  userID: "",
};

export const platformEarning = {
  id: "",
  amount: "",
};

export const userData = {
  id: "",
  userID: "",
  isSuperAdmin: false,
  isAdmin: false,
  isAuthenticated: false,
  isActive: false,
  isInit: false,
  isAgent: false,
  isAgentPortalEnabled: false,
  isPartner: false,
  isBanned: false,
  isRegisterBusinessEnabledUSA: false,
  isLocked: false,
  isTransferPamEnabled: false,
  isAnnouncementEnabled: false,
  isCallCenterEnabled: false,
  isPhoneVerified: false,
  isVerified: true,
  isComplyCubeVerified: true,
  isTopUpEnabled: false,
  isStripeEnabled: false,
  isWithdrawEnabled: false,
  isVideoTrainingEnabled: false,
  isPaymeEnabled: false,
  isAPIEnabled: false,
  isCryptoEnabled: false,
  isNatCashEnabled: false,
  isInviteEnabled: false,
  isPayPalEnabled: false,
  isLongerThanSevenDays: false,
  isLongerThanTenDays: false,
  isLongerThanTwoDays: false,
  isPinEnabled: false,
  isVirtualCardEnabled: false,
  isMonCashEnabled: false,
  isPayPalRewardsEnabled: false,
  isTransferEnabled: false,
  isEcommerceEnabled: true,
  isCashAppEnabled: false,
  isZelleEnabled: false,
  isPayPalPayoutEnabled: false,
  isUSABankRewardsEnabled: false,
  isBankWithdrawEnabled: false,
  isPhysicalCardRewardsEnabled: false,
  isBalanceEnabled: false,
  isPGPayEnabled: false,
  isStripeIssuingEnabled: false,
  isNewVisaDesign: false,
  gender: IGender.MALE,
  fullName: "",
  privateNote: "",
  sinceByDays: 0,
  stripePaymentLink: "",
  cardHolderId: "",
  cardId: "",
  apiPublicKey: "",
  email: "",
  phone: "",
  birthDay: "",
  pgPayQRCodeUrl: "",
  line1: "",
  line2: "",
  referenceCode: "",
  city: "",
  state: "",
  postal_code: "",
  country: "CA",
  customerID: "",
  cardQuantity: 0,

  // Subscriptions for Blue Card, and Being an Agent
  agentSubscriptionStart: "",
  agentSubscriptionEnd: "",
  blueCardSubscriptionStart: "",
  blueCardSubscriptionEnd: "",
  identityVerificationId: "",
  profileImageUrl: "",

  // Object
  balance,
  setting,
  globalSetting,
  earning,
  platformEarning,
  payme: defaultPayme,

  // Array
  notification: [],
  orderHistories: [],
  virtualCards: [],
  favorites: [],
  cardTransactions: {
    transactions: [],
    cardIds: [],
  },

  createdAt: "2022-04-27T07:37:49.321Z",
  updatedAt: "2022-05-04T05:12:56.878Z",
};

export const prePaidUserData = {
  id: "",
  object: "",
  brand: "Visa",

  cancellation_reason: null,
  cardholder: {
    id: "",
    object: "",
    billing: {
      address: {
        city: "",
        country: "",
        line1: "",
        line2: null,
        postal_code: "",
        state: "",
      },
    },
    company: null,
    created: 0,
    email: "",
    individual: null,
    livemode: false,
    metadata: {
      email: "",
      isPrepaid: "true",
      userID: "",
    },
    name: "",
    phone_number: null,
    requirements: {
      disabled_reason: null,
      past_due: [],
    },
    spending_controls: {
      allowed_categories: [],
      blocked_categories: [],
      spending_limits: [],
      spending_limits_currency: null,
    },
    status: "active",
    type: "individual",
  },
  created: 1659468027,
  currency: "usd",
  exp_month: 7,
  exp_year: 2025,
  financial_account: null,
  last4: "",
  livemode: false,
  metadata: {
    email: "",
    isPrepaid: "true",
    userID: "",
  },
  replaced_by: null,
  replacement_for: null,
  replacement_reason: null,
  shipping: null,
  spending_controls: {
    allowed_categories: null,
    blocked_categories: null,
    spending_limits: [
      {
        amount: 50000,
        categories: [],
        interval: "daily",
      },
    ],
    spending_limits_currency: "usd",
  },
  status: "",
  type: "",
  wallets: {
    apple_pay: {
      eligible: true,
      ineligible_reason: null,
    },
    google_pay: {
      eligible: true,
      ineligible_reason: null,
    },
    primary_account_identifier: null,
  },
  user: {
    line1: "",
    createdAt: "",
    cardHolderId: "",
    fullName: "",
    email: "",
    country: "",
    prepaidUrl: "",
    state: "",
    city: "",
    isActive: true,
    balance: 0,
    updatedAt: "",
    userID: "",
    id: "",
    pendingAmount: 0,
    isPinEnabled: true,
  },
};

export const tpCardIssueDefaultData = {
  path: "api/merchant/card/issue",
  status: "success",
  message: "Card Application Sent To Admin For Approval. 24-48Hours",
  data: {
    holder_id: uuidv4(),
    firstname: "Test",
    lastname: "Sandbox",
    email: "info@pgecom.com",
    mobile: "13477594345",
    address: {
      line_1: "Petion Ville",
      line_2: null,
      city: "Port au prince",
      state: "Ouest",
      country: "Haiti",
      zip: "6119",
    },
  },
};

export const flutterwaveCardIssueDefaultData = (
  user: IUser,
  ftwCard: IFlutterWaveCardResponse
) => ({
  status: "success",
  message: "Card created successfully",
  data: {
    id: null,
    account_id: null,
    amount: ftwCard?.data?.amount,
    currency: "USD",
    card_pan: "**** **** **** ****",
    masked_pan: "*** *** *** ****",
    city: null,
    state: null,
    address_1: null,
    address_2: null,
    zip_code: null,
    cvv: null,
    expiration: null,
    send_to: null,
    bin_check_name: null,
    card_type: "mastercard",
    name_on_card: `${ftwCard.data?.name_on_card}`,
    created_at: null,
    is_active: true,
    callback_url: "https://webhook.site/b67965fa-e57c-4dda-84ce-0f8d6739b8a5",
  },
});

export const pgPayTokenDefaultData = {
  userID: "",
  amount: 0,
  pgPayMerchantName: "PGecom",
  transactionType: "",
  giftCardId: "",
  isProd: false,
  phone: "",
  redirectUrl: "",
  orderId: "",
  quantity: 1,
  customerEmail: "",
  customerFirstName: "",
  customerLastName: "",
  iat: "",
  exp: "",
};

export default userData;
