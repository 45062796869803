import { createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";
import { IInvestment } from "@Types/Iinvestment";

const initialState: IInvestment[] = [];

export const investmentSlicer = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setInvestments: (state, action) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setInvestments } = investmentSlicer.actions;

export default investmentSlicer.reducer;
