import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { adminUserApi } from "@Libs/admin/user";
import { IPrivateNote } from "@Types/Iprivatenote";

const initialState: IPrivateNote[] = [];

export const paymeSlicer = createSlice({
  name: "privatenotes",
  initialState,
  reducers: {
    addNotes: (state, action) => action?.payload,
    removeNotes: (state, action: PayloadAction<any>) => action?.payload,
    setPrivateNotes: (state, action: PayloadAction<IPrivateNote[]>) => {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder?.addMatcher(
      adminUserApi.endpoints.getNotesByUserID.matchFulfilled,
      (state, action) => action.payload
    );
  },
});

// Action creators are generated for each case reducer function
export const { addNotes, removeNotes, setPrivateNotes } = paymeSlicer.actions;

export default paymeSlicer.reducer;
