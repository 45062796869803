import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export * from "./api-user";
export * from "./api-stripe";
export * from "./api-balance";
export * from "./api-topup";
export * from "./api-virtual-card";
export * from "./api-email";
export * from "./api-crypto";
export * from "./api-card";
export * from "./api-pin";
export * from "./api-transaction";
export * from "./api-authorizations";

// Define a service using a base URL and expected endpoints
export const pgRewardsApi = createApi({
  reducerPath: "pgRewardsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    "User",
    "Balance",
    "Card",
    "Crypto",
    "Pin",
    "Authorizations",
    "PartnerBalance",
    "PrivateNotes",
  ],
});
