import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IOrderHistory, IUser } from "@Types";
import {
  IReloadlyGiftCardResponse,
  IReloadlyProduct,
  IReloadlyRedemptionCode,
} from "@Types/Ireloadly";

export type IOrderDetail = {
  sender: IUser;
  order: IOrderHistory;
  product: IReloadlyProduct;
  transaction: IReloadlyGiftCardResponse;

  redemptionCode: IReloadlyRedemptionCode[];
  owner: IUser;
  receiver: IUser;
};

const initialState: IOrderDetail = {
  sender: {},
  order: {},
  product: {},
  transaction: {},
  redemptionCode: [],
  owner: {},
  receiver: {},
};

export const orderDetailSlice = createSlice({
  name: "orderDetail",
  initialState,
  reducers: {
    setOrderDetail: (state, action: PayloadAction<any>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setOrderDetail } = orderDetailSlice.actions;

export default orderDetailSlice.reducer;
