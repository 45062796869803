import { IPaymentMethod } from "@Types";

export enum IPaymentMethodStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum IPaymentMethodCategory {
  DIGITAL_PAYMENTS = "Digital Payments",
  BANKS = "Banks",
  E_WALLETS = "E-Wallets",
  MONEY_TRANSFER = "Money Transfer",
  PAYMENT_GATEWAYS = "Payment Gateways",
  MOBILE_MONEY = "Mobile Money",
  POPULAR_IN_HAITI = "Popular in Haiti",
  POPULAR_IN_USA = "Popular in USA",
}

export interface IUserPaymentMethod {
  id?: string;
  fields?: any;
  type?: string;
  label?: string;
  category?: string;

  // Deprecated
  last4Digit?: number;
  name?: string;
  status?: IPaymentMethodStatus;
  userID?: string;
  email?: string;
  phone?: string;
  method?: IPaymentMethod;
  createdAt?: string;
  updatedAt?: string;
}
