import { TransactionType } from "@Types";
import axios from "axios";

type ISendEmailPayload = {
  balance: string;
  amount: number;
  transactionType: TransactionType;
  adminEmail: string;
  email: string;
  description: string;
};

export const sendEmail = async (payload: ISendEmailPayload) => {
  try {
    const { data } = await axios({
      url: "/api/email/send",
      data: payload,
      method: "POST",
    });

    return data;
  } catch (error) {
    //  Error sending email");
  }
};
