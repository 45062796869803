import { IPaymentMethod } from "./Iorderhistory";
import { OrderStatus } from "./Iorderstatus";

export type IGiftCard = {
  id?: string;
  brandId?: string;
  giftCardId?: GiftCardId;
  giftCardName?: string;
  providerFee?: string;
  brandName?: string;
  isPriceFixed?: boolean;
  redeemptionInstructions?: string;
  minimumAmount?: string;
  maximumAmount?: string;
  currency?: string;
  country?: string;
  logoUrl?: string[];
  updatedAt?: string;
  productId?: number;
  productName?: string;
  isManual?: boolean;
  global?: false;
  senderFee?: number;
  senderFeePercentage?: number;
  discountPercentage?: number;
  denominationType?: string;
  recipientCurrencyCode?: string;
  minRecipientDenomination?: number;
  maxRecipientDenomination?: number;
  senderCurrencyCode?: string;
  minSenderDenomination?: number;
  maxSenderDenomination?: number;
  fixedRecipientDenominations?: number[];
  fixedSenderDenominations?: number[];
  fixedRecipientToSenderDenominationsMap?: {};
  logoUrls?: string[];
  brand?: {
    brandId?: number;
    brandName?: string;
  };
  redeemInstruction?: {
    concise?: string;
    verbose?: string;
  };
};

export interface IGiftCardFees {
  transactionFees: number;
  deliveryFees: number;
  totalFees: number;
}
export interface IGiftCardMetadata {
  giftCardId?: GiftCardId;
  giftCardName?: string;
  firstName?: string;
  lastName?: string;
  quantity?: number;
  delivery?: GiftCardDelivery;
  email?: string;
  amount?: number;
  phone?: string;
  userID?: string;
  giftCardFees?: number;
  transactionType?: string;
  affiliateUserID?: string;
  affiliateEmail?: string;
  affiliateFullName?: string;
  giftCardOrderStatus?: OrderStatus;
  status?: OrderStatus;
  gdes?: number;
  paymentMethod?: IPaymentMethod;
  userFee?: number;
  channel?: string;
}

export enum GiftCardId {
  AMAZON = "5",
  EBAY = "1386",
  UBER = "13043",
  BINANCE_US = "16235",
  BINANCE_HT = "16159",
  GOOGLE_PLAY_US = "3940",
  VISA = "18598",
  // TREMENDOUS_VISA = "18280",
  // TREMENDOUS_VISA = "18587", EURO Visa
  TREMENDOUS_VISA = "18585",
  VISA_CANADA = "16679",
  MASTERCARD = "18597",
  FREE_FIRE = "3619",
  Apple = "21",
  SHEIN = "18668",
  NETFLIX = "16257",
  ALIBABA = "-0",
  FACEBOOK = "-0",
  PAYPAL = "+1",
  CASHAPP = "+2",
  UDEMY = "-0",
  GENERAL = "-0",
}

export enum GiftCardBrandId {
  AMAZON = "2",
  EBAY = "16",
  BINANCE_US = "320",
  BINANCE_HT = "320",
  GOOGLE_PLAY_US = "25",
  UBER = "87",
  // VISA = "16921",
  // VISA_CANADA = "16679",
  // TREMENDOUS_VISA = "18280",
  // TREMENDOUS_VISA = "18587", Euro Visa
  TREMENDOUS_VISA = "18585",
  MASTERCARD = "16923",
  FREE_FIRE = "22",
  NETFLIX = "16257",
  Apple = "3",
  SHEIN = "326",
  ALIBABA = "-0",
  PAYPAL = "+1",
  CASHAPP = "+2",
  FACEBOOK = "-0",
  UDEMY = "-0",
  GENERAL = "-0",
}

export enum GiftCardDelivery {
  STANDARD = "standard",
  REGULAR = "regular",
  EXPRESS = "express",
  URGENT = "urgent",
  INSTANT = "instant",
}

export enum IGiftCardStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESSFUL",
  FAILED = "FAILED",
}

export enum IGiftCardWebHookEventType {
  GIFTCARD_STATUS = "giftcard_transaction.status",
  PROCESSING = "airtime_transaction.status",
}

export interface IGiftCardTransaction {
  transactionId?: number;
  amount?: number;
  discount?: number;
  currencyCode?: string;
  fee?: number;
  smsFee?: number;
  totalFee?: number;
  recipientEmail?: string;
  recipientPhone?: null;
  customIdentifier?: string;
  status?: string;
  transactionCreatedTime?: string;
  product?: {
    productId?: GiftCardId;
    productName?: string;
    countryCode?: string;
    quantity?: number;
    unitPrice?: number;
    totalPrice?: number;
    currencyCode?: string;
    brand?: {
      brandId?: number;
      brandName?: string;
    };
  };
}

export interface IGiftCardWebhookResponse {
  id: number;
  type: IGiftCardWebHookEventType;
  liveMode: boolean;
  date: string;
  data: {
    transactionId: number;
    amount: number;
    discount: number;
    currencyCode: string;
    fee: number;
    smsFee: number;
    totalFee: number;
    preOrdered: false;
    recipientEmail: string;
    recipientPhone: null;
    customIdentifier: string;
    status: IGiftCardStatus;
    transactionCreatedTime: string;
    product: {
      productId: number;
      productName: string;
      countryCode: string;
      quantity: number;
      unitPrice: number;
      totalPrice: number;
      currencyCode: string;
      brand: any;
    };
  };
}

export interface IGiftCardRedemptionCode {
  cardNumber: number;
  pinCode: number;
}

export interface IGlobalGiftCardSettings {
  id?: string;
  isStandardDelivery?: boolean;
  isRegularDelivery?: boolean;
  isExpressDelivery?: boolean;
  isUrgentDelivery?: boolean;
  isInstantDelivery?: boolean;
  defaultDelivery?: GiftCardDelivery;

  // Delivery Fees
  standardDeliveryFee?: number;
  regularDeliveryFee?: number;
  expressDeliveryFee?: number;
  urgentDeliveryFee?: number;
  instantDeliveryFee?: number;
  discountedInstantDeliveryFee?: number;

  isActivated?: boolean;
  isPriceFixed?: boolean;
  isOutOfStock?: boolean;
  type?: string;

  provider?: string;
  providerId?: GiftCardId;
  image?: string;
  displayName?: string;

  feePercentage?: number;
  fixedFee?: number;

  minimum?: number;
  maximum?: number;
  platform?: string;
}

export enum IGlobalGiftCardSettingType {
  VISA = "visa",
  MASTERCARD = "mastercard",
  NETFLIX = "netflix",
  PAYPAL = "paypal",
  CASHAPP = "cashapp",
  BINANCE = "binance",
  APPLE = "apple",
  AMAZON = "amazon",
  EBAY = "ebay",
  SOGEBANK = "sogebank",
  FREE_FIRE = "freefire",
  SHEIN = "shein",
  UBER = "uber",
}
