import { createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";
import { IOrderHistory } from "@Types";

const initialState: IOrderHistory[] = [];

export const orderHistorySlicer = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setUserOrders: (state, action) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setUserOrders } = orderHistorySlicer.actions;

export default orderHistorySlicer.reducer;
