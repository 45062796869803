import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "@Types";
import { adminUserApi } from "@Libs/admin/user";

const initialState: IUser[] = [];

export const userSlice = createSlice({
  name: "userlist",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<IUser[]>) => action.payload,
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder.addMatcher(
      adminUserApi.endpoints.searchUsers.matchFulfilled,
      (state, action) => action.payload
    );
  },
});

// Action creators are generated for each case reducer function
export const { setUsers } = userSlice.actions;

export default userSlice.reducer;
