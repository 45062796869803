import { IBalance } from "@Types/Ibalance";
import {
  IEarning,
  IPlatformEarning,
  IGlobalSettings,
  IPayme,
  INotification,
  IOrderHistory,
  IVirtualCard,
  IFavorite,
  ICardTransactionProps,
} from "@Types";

export enum IGender {
  MALE = "Male",
  FEMALE = "Female",
}

export enum IPhysicalCardPaymentStatus {
  PENDING = "physical_card_payment_pending",
  PAID = "physical_card_payment_paid",
  UNPAID = "physical_card_payment_unpaid",
  VOIDED = "physical_card_payment_voided",
}

export enum IPhysicalCardStatus {
  IN_TRANSIT = "physical_card_in_transit",
  ORDER_PLACED = "physical_card_order_placed",
  SHIPPED = "physical_card_shipped",
  DELIVERED = "physical_card_delivered",
  INTERNATIONAL_TRANSIT = "physical_card_international_transit",
  ACTIVATED = "physical_card_activated",
  RETURNED = "physical_card_returned",
  LOST = "physical_card_lost",
  STOLEN = "physical_card_stolen",
  DEACTIVATED = "physical_card_deactivated",
  NOT_NEEDED = "physical_card_not_needed",
  NEXT_FINAL_DESTINATION = "physical_card_next_final_destination",
  AVAILABLE_FOR_PICKUP = "physical_card_available_for_pickup",
}

export interface IUserSetting {
  id: string;
  userID: string;
  cardFees: number;
  virtualCardFees: number;
  transFerFees: number;
  topUpFees: number;
  paypalFees: number;
  physicalCardFees?: number;
  bankRewardFees?: number;
}

export enum ICardType {
  MASTERCARD = "mastercard",
  VISA = "visa",
  VISA_PREPAID = "visa_prepaid",
  CRYPTO = "crypto",
  VISA_SUDO = "visa_sudo",
  MASTERCARD_SUDO = "mastercard_sudo",
}

export interface IUser {
  // Deprecated
  apiKeySecret?: string;

  // Feature Flag
  isAgent?: boolean;
  isVideoTrainingEnabled?: boolean;
  isPartner?: boolean;
  isActive?: boolean;
  isDivvyCardEnabled?: boolean;
  isPGPayEnabled?: boolean;
  isBanned?: boolean;
  isEcommerceEnabled?: boolean;
  isLocked?: boolean;
  isTwoFactorRequired?: boolean;
  isVerified?: boolean;
  isPaymeEnabled?: boolean;
  isPlaidEnabled?: boolean;
  isAccountLimited?: boolean;
  isPhoneVerified?: boolean;
  isEmailVerified?: boolean;
  isUnibankEnabled?: boolean;
  isSudoMasterCardInstantDepositEnabled?: boolean;
  isBUHEnabled?: boolean;
  isCryptoEnabled?: boolean;
  isCreditCardEnabled?: boolean;
  isPaymePremiumEnabled?: boolean;
  isComplyCubeIdentityEnabled?: boolean;
  isComplyCubeVerified?: boolean;
  isPrepaid?: boolean;
  isTwoFactorActivated?: boolean;
  isReloadlyGiftCardEnabled?: boolean;
  isInviteEnabled?: boolean;
  isPayPalEnabled?: boolean;
  isPinEnabled?: boolean;
  isDeleted?: boolean;
  isAPIRequest?: boolean;
  isAPIEnabled?: boolean;
  isMultipleShopEnabled?: boolean;
  isTPMasterCardEnabled?: boolean;
  isWithdrawEnabled?: boolean;
  isLoanEnabled?: boolean;
  isFlightEnabled?: boolean;
  isInvestmentEnabled?: boolean;
  isMonCashAutomaticWithdrawEnabled?: boolean;
  isCryptoPaymentEnabled?: boolean;
  isBankWithdrawEnabled?: boolean;
  isCashAppEnabled?: boolean;
  isCashAppWithdrawEnabled?: boolean;
  isZelleEnabled?: boolean;
  isNetlifyIntegrationEnabled?: boolean;
  isFlutterWaveCreditCardEnabled?: boolean;
  isUberAccountConnected?: boolean;
  isAmazonAccountConnected?: boolean;
  isVirtualCardEnabled?: boolean;
  isMultipleReceiptUploadEnabled?: boolean;
  isPayPalRewardsEnabled?: boolean;
  isTransferEnabled?: boolean;
  isUSABankRewardsEnabled?: boolean;
  isPhysicalCardRewardsEnabled?: boolean;
  isBalanceEnabled?: boolean;
  isStripeEnabled?: boolean;
  isTradingEnabled?: boolean;
  isAddressVerified?: boolean;
  isBusinessEnabled?: boolean;
  isFlutterWavePaymeEnabled?: boolean;
  isTicketDisabled?: boolean;
  isFraud?: boolean;
  isTopUpEnabled?: boolean;
  isNewVisaDesign?: boolean;
  isConvertEnabled?: boolean;
  isMonCashEnabled?: boolean;
  isFinancialDashboardEnabled?: boolean;
  isNatCashEnabled?: boolean;
  isRadarEnabled?: boolean;
  isRegisterBusinessEnabledUSA?: boolean;
  isAffiliateEnabled?: boolean;
  isPayPalPayoutEnabled?: boolean;
  isAgentPortalEnabled?: boolean;
  isTransferPamEnabled?: boolean;
  isInit?: boolean;
  isOnboardingCompleted?: boolean;
  isCallCenterEnabled?: boolean;
  isStripeIssuingEnabled?: boolean;
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  isLongerThanSevenDays?: boolean;
  isVisaStandardDepositDeliveryEnabled?: boolean;

  // KYC Flags
  isDocumentClear?: boolean;
  isIdentityClear?: boolean;
  isAMLClear?: boolean;

  email?: string;
  userTimeZone?: string;
  oldEmail?: string;
  gender?: IGender;
  cardHolderId?: string;
  pgPayMerchantName?: string;

  pgPayReturnUrl?: string; // Deprecated
  pgPaySuccessUrl?: string;
  pgPayErrorUrl?: string;

  asanaSectionId?: string;

  profileImageUrl?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  youtubeUrl?: string;
  twitterUrl?: string;
  linkedinUrl?: string;
  tiktokUrl?: string;

  // Physical Card Payment and Shipping Status
  physicalCardShippingStatus?: IPhysicalCardStatus;
  physicalPaymentStatus?: IPhysicalCardPaymentStatus;
  physicalCardDestinationCountry?: string;
  physicalCardDestinationCity?: string;

  cardId?: string;
  prepaidUrl?: string;
  company?: string;
  industry?: any;
  lastName?: string;
  firstName?: string;
  status?: IUserStatus;
  lastActive?: number;
  verifiedFirstName?: string;
  verifiedLastName?: string;
  cardHolderFullName?: string;
  verifiedDOB?: any;
  verifiedNationality?: string;
  last4DigitsPrimaryCard?: string;
  jobTitle?: string;
  countryCode?: string;
  apiPublicKey?: string;
  customerID?: string;
  identityVerificationId?: string;
  complyCubeVerificationId?: string;
  cardQuantity?: number;
  pgPayQRCodeUrl?: string;
  language?: string;
  fullName?: string;
  tpCardHolderId?: string;
  asanaUserId?: string;
  wordpressUserId?: string;

  // Subscription
  agentSubscriptionStart?: string;
  agentSubscriptionEnd?: string;
  blueCardSubscriptionStart?: string;
  blueCardSubscriptionEnd?: string;

  crispPeopleURL?: string;
  sinceByDays?: number;
  phone?: string;
  secondaryPhone?: string;
  whatsappPhone?: string;
  id?: string;
  ip?: string;
  livitPayCardId?: string;
  livitPayCardType?: ICardType;
  livitPayMasterCardEmail?: string;
  sudoCustomerId?: string;
  crispUserId?: string;
  agentID?: string;
  userID?: string;
  ftwCardHolderId?: string;
  referenceDiscountAmount?: number;
  divvyCardHolderId?: string;
  referenceCode?: string;
  balance?: IBalance | any;
  birthDay?: string;
  privateNote?: string;
  stripePaymentLink?: string;

  // User Address
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;

  // Add information about the person who referred the user
  referalId?: string;
  referredBy?: string;
  referredByFullName?: string;
  referredByReferenceCode?: string;

  // Created and Updated At
  updatedAt?: string;
  createdAt?: string;
}

export type IUserProfile = IUser & {
  isLongerThanSevenDays: boolean;
  isLongerThanTwoDays: boolean;
  isLongerThanTenDays: boolean;
  isAuthenticated: boolean;
  isAnnouncementEnabled?: boolean;
  announcement?: string;
  announcementStatus?: any;
  isInit: boolean;
  balance: IBalance;
  setting: IUserSetting;
  earning: IEarning;
  platformEarning: IPlatformEarning;
  globalSetting: IGlobalSettings;
  payme: IPayme;

  notification: Array<INotification>;
  orderHistories: Array<IOrderHistory>;
  virtualCards: Array<IVirtualCard>;
  favorites: Array<IFavorite>;
  cardTransactions: ICardTransactionProps;
};

export enum UserType {
  AGENT = "agent",
  PARTNER = "partner",
  USER = "user",
  MEMBER = "member",
  CUSTOMER = "customer",
  EMPLOYEE = "employee",
  BUSINESS = "business",
  ADMIN = "admin",
  SHOPPER = "shopper",
  SUPER_ADMIN = "super_admin",
}

export enum SignUpViewType {
  MFA = "mfa",
  EMAIL_VERIFICATION_CODE = "email_verification_code",
  GOOGLE_AUTHENTICATOR = "google_authenticator",
  ONBOARDING = "onboarding",
}

export enum PGEcomMerchantId {
  DEV = "5f56dfa3-a415-4818-8275-44fc63ece3fd",
  PRODUCTION = "92cc9dbe-163e-403f-9059-68f846f4dac6",
}

export enum AuthenticationChallenge {
  SMS_MFA = "SMS_MFA",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  MFA_SETUP = "MFA_SETUP",
  EMAIL_MFA = "EMAIL_MFA",
  NOMFA = "NOMFA",
  SELECT_MFA_TYPE = "SELECT_MFA_TYPE",
  LOGIN = "LOGIN",
}

export enum MFAType {
  SMS = "SMS",
  NOMFA = "NOMFA",
  TOTP = "TOTP",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
  MFA_DEACTIVATED = "MFA_DEACTIVATED",
}

export enum IUserIndexByName {
  BY_DATE = "byDate",
  BY_EMAIL = "byEmail",
  BY_FULL_NAME = "byFullName",
  BY_COUNTRY = "byCountry",
  BY_PHONE = "byPhone",
  BY_REFERENCE_CODE = "byReferenceCode",
}

export enum IUserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
  BANNED = "banned",
  LOCKED = "locked",
  DELETED = "deleted",
  SUSPENDED = "suspended",
  UNVERIFIED = "unverified",
  VERIFIED = "verified",
  FRAUD = "fraud",
  UNKNOWN = "unknown",
}
