import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { INetlifySite } from "@Types/Inetlify";

const initialState: INetlifySite = {};

export const siteReducer = createSlice({
  name: "site",
  initialState,
  reducers: {
    setNetlifySite: (state, action: PayloadAction<INetlifySite>) =>
      action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setNetlifySite } = siteReducer.actions;

export default siteReducer.reducer;
