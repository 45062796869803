import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { extendedApi } from "@Libs/user/payme";
import { IAffiliate } from "@Types/Iaffiliate";

const initialState: IAffiliate = {};

export const affiliateReducer = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    setAffiliate: (state, action: PayloadAction<IAffiliate>) => action.payload,
  },
  extraReducers: (builder) => {
    builder?.addMatcher(
      extendedApi.endpoints.getPayme.matchFulfilled,
      (state, action) => action.payload
    );
  },
});

// Action creators are generated for each case reducer function
export const { setAffiliate } = affiliateReducer.actions;

export default affiliateReducer.reducer;
