import { pgRewardsApi } from "@Libs";
import { IPayme } from "@Types";
import { IPaymePayload } from "@Types/Ipayload";

export const extendedApi = pgRewardsApi.injectEndpoints({
  endpoints: (builder) => ({
    // Query
    getPayme: builder.query<IPayme, void>({
      query: () => `/user/payme`,
      providesTags: [],
    }),

    // Mutatation
    updatePayme: builder.mutation<any, IPaymePayload>({
      query: (body: IPaymePayload) => ({
        url: `/user/payme`,
        method: "POST",
        body,
      }),
      invalidatesTags: [],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetPaymeQuery,
  useUpdatePaymeMutation,
  usePrefetch,
} = extendedApi;
