import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IBalance,
  ILocation,
  IOrderHistory,
  IPayme,
  ITPPCardTransaction,
  IUser,
} from "@Types";
import { adminUserApi } from "@Libs/admin/user";
import { IPrivateNote } from "@Types/Iprivatenote";
import { IActivity } from "@Types/Iactivity";
import { IShop } from "@Types/Ishop";
import { IProduct } from "@Types/Iproduct";
import { IGiftCardTransaction } from "@Types/Igiftcard";
import { ILivitPayMasterCard } from "@Types/Ilivitpay";
import { IIntegration } from "@Types/Iintegration";
import { ILoan } from "@Types/Iloan";
import { initialCardState } from "./cardReducer";

type IInitialStateProps = {
  balance?: IBalance;
  loan?: ILoan;
  receiverBalance?: IBalance;
  payme?: IPayme;
  card?: ILivitPayMasterCard;
  orders?: IOrderHistory[];
  giftCardOrders?: IOrderHistory[];
  shops?: IShop[];
  locations?: ILocation[];
  products?: IProduct[];
  notes?: IPrivateNote[];
  activities?: IActivity[];
  giftcards?: IGiftCardTransaction[];
  adminActivities?: IActivity[];
  transactions?: any;
  member?: IUser;
  integrations?: IIntegration[];
  orderDetails?: IOrderHistory;
  receiver?: IUser;
  stripeCard?: any;
  tppOrders?: ITPPCardTransaction[];
};

const initialState: IInitialStateProps = {
  member: {},
  balance: {},
  loan: {},
  receiverBalance: {},
  orders: [],
  notes: [],
  giftCardOrders: [],
  shops: [],
  locations: [],
  orderDetails: {
    userID: "",
  },
  receiver: {},
  giftcards: [],
  activities: [],
  integrations: [],
  adminActivities: [],
  transactions: [],
  products: [],
  payme: {},
  card: initialCardState,
  tppOrders: [],
  stripeCard: {},
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    updateMemberFields: (state, action: PayloadAction<IUser>) => {
      state.member = {
        ...state.member,
        ...action.payload,
      };
    },
    addFundsToPerson: (state, action: PayloadAction<number>) => {
      state.balance.amount += action?.payload;
    },

    addPersonCredit: (state, action: PayloadAction<number>) => {
      state.balance.credit += action?.payload;
    },

    substractPersonCredit: (state, action: PayloadAction<number>) => {
      state.balance.credit -= action?.payload;
    },

    addFundsToPersonPendingBalance: (state, action: PayloadAction<number>) => {
      state.balance.pendingAmount += action?.payload;
    },
    substractFundsToPersonPendingBalance: (
      state,
      action: PayloadAction<number>
    ) => {
      state.balance.pendingAmount -= action?.payload;
    },
    substractFundsFromPerson: (state, action: PayloadAction<number>) => {
      state.balance.amount -= action?.payload;
    },
    substractPendingFromPerson: (state, action: PayloadAction<number>) => {
      state.balance.pendingAmount -= action?.payload;
    },
    setMember: (state, action: PayloadAction<IUser>) => {
      state.member = action.payload;
    },

    setMemberOrderDetails: (state, action: PayloadAction<IOrderHistory>) => {
      state.orderDetails = action.payload;
    },

    updateMemberOrderDetails: (state, action: PayloadAction<IOrderHistory>) => {
      state.orderDetails = {
        ...state.orderDetails,
        ...action.payload,
      };
    },

    setMemberReceiver: (state, action: PayloadAction<IUser>) => {
      state.receiver = action.payload;
    },

    setMemberReceiverBalance: (state, action: PayloadAction<IBalance>) => {
      state.receiverBalance = action.payload;
    },

    setMemberCard: (state, action: PayloadAction<ILivitPayMasterCard>) => {
      state.card = action.payload;
    },

    setMemberTppCardHolderId: (state, action: PayloadAction<any>) => {
      state.member.tpCardHolderId = action.payload;
    },

    setMemberOrders: (state, action: PayloadAction<IOrderHistory[]>) => {
      state.orders = action.payload;
    },

    setMemberGiftCardOrders: (
      state,
      action: PayloadAction<IOrderHistory[]>
    ) => {
      state.giftCardOrders = action.payload;
    },

    setMemberGiftCards: (
      state,
      action: PayloadAction<IGiftCardTransaction[]>
    ) => {
      state.giftcards = action.payload;
    },

    setMemberTppOrders: (
      state,
      action: PayloadAction<ITPPCardTransaction[]>
    ) => {
      state.tppOrders = action.payload;
    },

    setMemberPayme: (state, action: PayloadAction<IPayme>) => {
      state.payme = action.payload;
    },

    setMemberActivities: (state, action: PayloadAction<IActivity[]>) => {
      state.activities = action.payload;
    },

    setMemberAdminActivities: (state, action: PayloadAction<IActivity[]>) => {
      state.adminActivities = action.payload;
    },

    setMemberBalance: (state, action: PayloadAction<IBalance>) => {
      state.balance = action.payload;
    },

    setMemberLoanBalance: (state, action: PayloadAction<ILoan>) => {
      state.loan = action.payload;
    },

    setMemberNotes: (state, action: PayloadAction<IPrivateNote[]>) => {
      state.notes = action.payload;
    },

    setMemberShops: (state, action: PayloadAction<IShop[]>) => {
      state.shops = action.payload;
    },

    setMemberLocations: (state, action: PayloadAction<ILocation[]>) => {
      state.locations = action.payload;
    },

    setMemberProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload;
    },

    setMemberStripeCard: (state, action: PayloadAction<any>) => {
      state.stripeCard = action.payload;
    },

    setMemberIntegrations: (state, action: PayloadAction<IIntegration[]>) => {
      state.integrations = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addMatcher(
      adminUserApi?.endpoints?.getUserByUserID?.matchFulfilled,
      (state, action) => action?.payload
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  updateMemberFields,
  addFundsToPerson,
  setMemberBalance,
  setMemberLoanBalance,
  substractFundsFromPerson,
  substractFundsToPersonPendingBalance,
  addFundsToPersonPendingBalance,
  substractPendingFromPerson,
  setMember,
  setMemberGiftCardOrders,
  setMemberPayme,
  setMemberShops,
  setMemberLocations,
  setMemberProducts,
  setMemberAdminActivities,
  setMemberOrders,
  setMemberStripeCard,
  setMemberNotes,
  setMemberTppOrders,
  setMemberCard,
  setMemberReceiverBalance,
  updateMemberOrderDetails,
  setMemberOrderDetails,
  setMemberReceiver,
  setMemberTppCardHolderId,
  setMemberGiftCards,
  setMemberActivities,
  addPersonCredit,
  substractPersonCredit,
  setMemberIntegrations,
} = memberSlice.actions;

export default memberSlice.reducer;
