import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "@Types";

import { userData } from "@Store/data";
import { extendedApi } from "@Libs/user/me";

const initialState: IUser = userData;

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    updateBusinessAction: (state, action: PayloadAction<IUser>) => ({
      ...state,
      ...action.payload,
    }),
    changeBusinessLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },

    setIsPINEnabled: (state, action: PayloadAction<boolean>) => {
      state.isPinEnabled = action.payload;
    },

    setBusiness: (state, action: PayloadAction<IUser>) => action.payload,
  },
  extraReducers: (builder) => {
    // Add reducers for additiona  l action types here, and handle loading state as needed
    builder.addMatcher(
      extendedApi.endpoints.me.matchFulfilled,
      (state, action) => ({
        ...action.payload,
        isInit: true,
      })
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  updateBusinessAction,
  changeBusinessLanguage,
  setBusiness,
  setIsPINEnabled,
} = businessSlice.actions;

export default businessSlice.reducer;
