import { stringList } from "aws-sdk/clients/datapipeline";

export type ICrispCompany = {
  name: string;
  legal_name: string;
  domain: string;
  url: stringList;
  description: string;
  timezone: number;

  metrics: {
    employees: number;
    market_cap: number;
    raised: number;
    arr: number;
  };

  geolocation: {
    country: string;
    region: string;
    city: string;

    coordinates: {
      latitude: number;
      longitude: number;
    };
  };

  tags: string[];

  phones: [];

  emails: string[];
};

type IProfilesData = {
  type: string;
  handle: string;
};

export type ICrispPerson = {
  nickname: string;
  gender: string;
  phone: string;
  address: string;
  description: string;
  website: string;
  timezone: number;

  profiles: IProfilesData[];

  employment: {
    name: string;
    domain: string;
    title: string;
    role: string;
    seniority: string;
  };

  geolocation: {
    country: string;
    region: string;
    city: string;

    coordinates: {
      latitude: number;
      longitude: number;
    };
  };

  locales: string[];
};

export type ICrispPeople = {
  error: boolean;
  reason: string;
  people_id: string;

  email: string;

  person: any;

  company: any;

  segments: string[];

  notepad: string;

  active: {
    now: false;
  };

  created_at: number;
  updated_at: number;
};

export enum ICrispSegment {
  BILL_CARD_HOLDER = "bill_card_holder",
  GIFTCARD_VISA = "giftcard_visa",
  DEVELOPER = "developer",
}
